import { GridColDef } from "@mui/x-data-grid/models";

export enum Vat_enum {
  _14 = "7.00", // Stawka 7% dla rolników ryczałtowych
  _15 = "5.00", // Stawka podstawowa 5%
  _8 = "0.00",   // Stawka 0% dla sprzedaży krajowej i eksportowej
  _null = "-"
}
export function getVatKeyByValue(value: string) {
  const indexOfS = Object.values(Vat_enum).indexOf(value as unknown as Vat_enum);
  const key = Object.keys(Vat_enum)[indexOfS];
  return key;
}
export const vat_options: any[] = [
  {
    id: "14",
    label: "7.00"
  },
  {
    id: "15",
    label: "5.00"
  },
  {
    id: "8",
    label: "0.00"
  }
]
export interface IdAndLabel {
    id: string;
    label: string;
}

// export interface Supply {
//     id: number;
//     numberWZ: string | null;
//     ownedBy: string | null;
//     sendToSPGJ: boolean;
//     sendDate: Date;
//     approvedBy: string;
//     numberPZ: string;
//     createdDate: Date;
//     createdBy: string | null;
// }
export const supplyColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'numberWZ',
    headerName: 'WZ',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100
  },
  {
    field: 'ownedBy',
    headerName: 'Właściciel',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 190
  },
  {
    field: 'sendToSpgj',
    headerName: 'Wysłane do SPGJ',
    type: 'boolean',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 85,
    align: "center"
  },
  {
    field: 'sendToSpgjDate',
    headerName: 'Otrzymano SPGJ',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 170,
    valueGetter: (value) => value && new Date(value)
  },
  {
    field: 'numberPZ',
    headerName: 'PZ',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 125
  },
  {
    field: 'approvedBy',
    headerName: 'Zaakceptowano przez',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 190
  },
  {
    field: 'approvedDate',
    headerName: 'Zaakceptowany',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 160,
    valueGetter: ( value ) => value && new Date(value)
  },
  {
    field: 'createdBy',
    headerName: 'Twórca',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 190
  },
  {
    field: 'createdDate',
    headerName: 'Utworzony',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 170,
    valueGetter: ( value ) => value && new Date(value)
  }
];

export const supplyDetailsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center",
    valueGetter: ( value ) => value > 100000000 ? "oryg." : value
  },
  {
    field: 'towarName',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    width: 380,
    disableColumnMenu: true
  },
  {
    field: 'quantity',
    headerName: 'Ilość',
    type: 'number',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'price',
    headerName: 'Cena',
    type: "number",
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center",
    // valueParser(value, params?) {
    //   var currentValue = value as string;
    //   if (currentValue === "0") {
    //     currentValue = currentValue.replace('0', '0.01');
    //   }
    //   var data = Number(currentValue);
    //   return data >= 0.01 ? data : null
    // },
    valueFormatter: ( value ) => (value !== null && value !== undefined ? (value as string).toString() : null)
  },
  {
    field: 'vat_WartoscStawki',
    headerName: 'Vat',
    type: 'singleSelect',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    valueOptions: Object.values(Vat_enum),
    headerAlign: "center",
    align: "center",
    valueGetter: ( value ) => value === null ? "-" : value
  },
  {
    field: 'additionalInfo',
    headerName: 'Uwagi',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 200
  }
];

export interface Towar {
  id: number;
  nazwa: string;
  jednostka: string;
  skrot: string;
  nazwaGrupy: string;
}
export interface TowaryApi {
  id: number;
  key: number;
  label: string;
  nazwaGrupy: string;
}
export const towaryColumns: GridColDef[] = [
  {
    field: 'idObject',
    headerName: 'Id towaru',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 80,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'nazwa',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    width: 380,
    disableColumnMenu: true
  },
  {
    field: 'jednostka',
    headerName: 'Jednostka',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 95
  },
  {
    field: 'skrot',
    headerName: 'Skrót',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 200
  },
  {
    field: 'nazwagrupy',
    headerName: 'Nazwa grupy',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 200
  },
  {
      field: 'price',
      headerName: 'Cena',
      type: "number",
      editable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      hideable: false,
      width: 100,
      headerAlign: "left",
      align: "left",
      // valueParser(value, params?) {
      //   var currentValue = value as string;
      //   if (currentValue === "0") {
      //     currentValue = currentValue.replace('0', '0.01');
      //   }
      //   var data = Number(currentValue);
      //   return data >= 0.01 ? data : null
      // },
      valueFormatter: ( value ) => (value !== null && value !== undefined ? (value as string).toString() : null)
  }
];