import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { darken, Grid, IconButton, InputAdornment, lighten, styled, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { ClearIcon, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { pl } from "date-fns/locale/pl";

interface AddWydanieModalProps {
  setDostawaDate: Dispatch<SetStateAction<Date | null>>;
  setDostawcaNumber: Dispatch<SetStateAction<string>>;
  setOrderNumber: Dispatch<SetStateAction<string>>;
	loadingCreateButton: boolean;
	addAction: () => Promise<void>;
}
function AddWydanieModal({
	setDostawaDate, setDostawcaNumber, setOrderNumber, loadingCreateButton, addAction
} : AddWydanieModalProps) {
  const handleDostawaDate = useCallback((event: Date | null) => {
		setDateValue(event);
    setDostawaDate(event);
  }, [setDostawaDate])
  const handleDostawcaNumber = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setDostawcaNumber(event.target.value);
  }, [setDostawcaNumber])
  const handleOrderNumber = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
    setOrderNumber(event.target.value);
  }, [setOrderNumber])
	
  const [dateValue, setDateValue] = useState<Date | null>(null);

	return (
		<>
			<h1 style={{ margin: '0 0 20px 0' }}>Dodaj wydanie zewnętrzne</h1>
			<Grid container spacing={0}>
				<Grid item xs={12} sx={{mb: '4px'}}>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
						<MobileDatePicker sx={{width: "100%"}}
							label={"Data dostawy"}
							value={dateValue}
							onChange={(newValue) => handleDostawaDate(newValue)}
							closeOnSelect
							slotProps={{
								toolbar: {
									hidden: true,
								},
								actionBar: {
									actions: [],
								},
								textField: {
									InputProps: {
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={(event) => {
													event.preventDefault();
													handleDostawaDate(null);
													}}>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)
									}
								}
							}}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={handleDostawcaNumber}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="DostawcaNumber"
						name="DostawcaNumber"
						label="Numer dostawcy"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={handleOrderNumber}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="OrderNumber"
						name="OrderNumber"
						label="Numer zamówienia"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingCreateButton}
						loadingPosition="center"
						variant="contained"
						onClick={addAction} >
					</LoadingButton>
				</Grid>
			</Grid>
		</>
	)
};

export default AddWydanieModal;