import { Tooltip, Avatar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid/models";

export interface IActionWithTowarDescription {
  id: number;
  towarName: string;
  quantity: number;
  sum: number;
  note: string;
  creationDate: Date;
  createdBy: string;
  ownerName: string;
  numberPZ: string;
  price: number;
  withPriceValidation: boolean;
}
export const actionWithTowarDescriptionColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'towarName',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 380,
    disableColumnMenu: true,
    renderCell: ({ value, row }) => (
      <>{value}
        {!row.withPriceValidation && <Tooltip title="Zmiana wewnętrzna"><Avatar className="miniAvatar" sx={{ marginLeft: '5px'}} variant="rounded">ZW</Avatar></Tooltip>}
      </>
    )
  },
  {
    field: 'quantity',
    headerName: 'Ilość',
    type: 'number',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'price',
    headerName: 'Cena',
    type: 'number',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'sum',
    headerName: 'Suma',
    type: 'number',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'note',
    headerName: 'Uwagi',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 150,
    disableColumnMenu: true
  },
  {
    field: 'ownerName',
    headerName: 'Dostawca',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 200,
  },
  {
    field: 'numberPZ',
    headerName: 'PZ',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 125
  },
  {
    field: 'createdBy',
    headerName: 'Twórca',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 200,
  },
  {
    field: 'createdDate',
    headerName: 'Utworzony',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: true,
    width: 170,
    valueGetter: ( value ) => value && new Date(value)
  },
];

export const wzColumn: GridColDef = 
  {
    field: 'numberWZ',
    headerName: 'WZ',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 125
  };