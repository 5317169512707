import { GridColDef } from "@mui/x-data-grid/models";

export const detailsOfTowarForOdbiorcaColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'Id',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 60,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'towar',
        headerName: 'Nazwa towaru',
        editable: false,
        sortable: false,
        hideable: false,
        width: 380,
        disableColumnMenu: true
    },
    {
        field: 'name',
        headerName: 'Zmieniona nazwa towaru',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 380
    },
    {
        field: 'threeKg',
        headerName: '3kg',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 80,
        type: "number",
        headerAlign: "left",
        align: "left"
    },
    {
        field: 'epsOneZeroFour',
        headerName: 'ESP104',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 80,
        type: "number",
        headerAlign: "left",
        align: "left"
    },
    {
        field: 'epsOneThreeSix',
        headerName: 'ESP136',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 80,
        type: "number",
        headerAlign: "left",
        align: "left"
    },
    {
        field: 'carton',
        headerName: 'Karton',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 80,
        type: "number",
        headerAlign: "left",
        align: "left"
    },
    {
        field: 'tenKg',
        headerName: '10kg',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 80,
        type: "number",
        headerAlign: "left",
        align: "left"
    }
];