import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import './App.css';
import AppBarAndLeftMenu from './Features/AppBarAndLeftMenu';
import Login from './Features/Login';
import { Jwt } from './Models/Jwt';
import { checkIfUserIsLogged, getCurrentUserData } from './Services/UserService';
import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from 'notistack';
import { plPL } from '@mui/material/locale';
import Decimal from 'decimal.js';
import { ClickAwayListener } from '@mui/material';

export type AppContextType = {
  isLoggedIn: boolean;
  updateIsLoggedIn: (updateTo: boolean) => void;
  userData: Jwt | null;
  columnVisibilityInUsersPageModel: GridColumnVisibilityModel;
  setColumnVisibilityInUsersPageModel: Dispatch<SetStateAction<GridColumnVisibilityModel>>;
  rowsNumberPageModel: number;
  setRowsNumberPageModel: Dispatch<SetStateAction<number>>;
};

export const AppContext = createContext<AppContextType | null> (null);

const theme = createTheme(
  {
    typography: {
      allVariants: {
        fontFamily: [
          'Rubik',
          'Inter',
          'Poppins',
          'GeneralSans',
          'Roboto',
          'sans-serif',
        ].join(','),
        fontSize: 14
      },
    },
  },
  plPL
);

function App() {
  Decimal.set({ rounding: Decimal.ROUND_HALF_UP })

  const [userData, setUserData] = useState<Jwt | null>(() => {
    return getCurrentUserData();
  })

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return checkIfUserIsLogged();
  })
  const updateIsLoggedIn = (updateTo: boolean) => {
    setIsLoggedIn(updateTo);
  }

  const [columnVisibilityInUsersPageModel, setColumnVisibilityInUsersPageModel] = useState<GridColumnVisibilityModel>({
    lastTimeLoggedDate: false,
    createdDate: false,
    updatedDate: false,
    updatedBy: false,
    forceLogin: false
  })

  const [rowsNumberPageModel, setRowsNumberPageModel] = useState<number>(10);

  const handleClickAway = async () => {
    closeSnackbar();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let jwtCorrect = checkIfUserIsLogged();
      if (jwtCorrect === false && isLoggedIn === true) {
        enqueueSnackbar('Proszę zalogować sie ponownie.', { variant: 'warning', preventDuplicate: true });
        setIsLoggedIn(jwtCorrect);
      }
    }, 60000); // 1 minute

    // if (isLoggedIn && (window.location.href.indexOf('refresh') !== -1 || window.location.href.indexOf('wrongData') !== -1)) {
    //   window.location.replace(window.location.href.replace('refresh', '').replace('wrongData', '').replace('?', ''));
    // } else if (!isLoggedIn) {
    //   if (window.location.href.indexOf('refresh') !== -1) {
    //     enqueueSnackbar('Proszę zalogować sie ponownie.', { variant: 'warning' });
    //   }
    //   if (window.location.href.indexOf('wrongData') !== -1) {
    //     enqueueSnackbar('Dane logowania są nie prawidłowe.', { variant: 'error' });
    //   }
    // }

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // So that the interval is not running when the component is gone.
    return () => clearInterval(interval);
  }, [setIsLoggedIn, updateIsLoggedIn, isLoggedIn])
  
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={15000} maxSnack={8} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >  
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="App">
            <AppContext.Provider value={{
              isLoggedIn: isLoggedIn,
              updateIsLoggedIn: updateIsLoggedIn,
              userData: userData,
              columnVisibilityInUsersPageModel: columnVisibilityInUsersPageModel,
              setColumnVisibilityInUsersPageModel: setColumnVisibilityInUsersPageModel,
              rowsNumberPageModel: rowsNumberPageModel,
              setRowsNumberPageModel: setRowsNumberPageModel
            }}>
              { isLoggedIn ? <AppBarAndLeftMenu /> : <Login setUserData={setUserData} /> }
            </AppContext.Provider>
          </div>
        </ClickAwayListener>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;