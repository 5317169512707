import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";
import { dateToJson } from "../Helpers/DateToJson";

export const getAllMagazynApi = (paginationModel: {pageSize: number; page: number;}, searchByOwnerId: number | null, searchByTowarId: number | null,
  dateFromValueForSearch: Date | null, dateToValueForSearch: Date | null, priceValueForSearch: string, barcodeForSearch: string) => {
  let query = `itemsOnPage=${paginationModel.pageSize}&pageNumber=${paginationModel.page}`;
  
  if (searchByOwnerId !== null && searchByOwnerId !== undefined)
    query = query + `&searchByOwnerId=${searchByOwnerId}`;

  if (searchByTowarId !== null && searchByTowarId !== undefined)
    query = query + `&searchByTowarId=${searchByTowarId}`;

  if (dateFromValueForSearch !== null && dateFromValueForSearch !== undefined)
    query = query + `&searchByCreationDateFrom=${dateToJson(dateFromValueForSearch)}`;

  if (dateToValueForSearch !== null && dateToValueForSearch !== undefined)
    query = query + `&searchByCreationDateTo=${dateToJson(dateToValueForSearch)}`;

  if (priceValueForSearch !== null && priceValueForSearch !== undefined && priceValueForSearch !== '')
    query = query + `&SearchByPrice=${priceValueForSearch}`;
  
  if (barcodeForSearch !== null && barcodeForSearch !== undefined && barcodeForSearch !== '')
    query = query + `&SearchByBarcode=${barcodeForSearch}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}Magazyn/GetAllMagazyn?${query}`, { headers: CustomHeaders() });
};

export const getAllTowaryInMagazynApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}Magazyn/GetAllTowaryInMagazyn`, { headers: CustomHeaders() });
};




export const getAllDostawcaForRozwagaApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllDostawcaForRozwaga`, { headers: CustomHeaders() });
};

export const getAllRozwagaApi = (
  paginationModel: {pageSize: number; page: number;},
  searchByOwnerId: number | null,
  dateFromValueForSearch: Date | null,
  dateToValueForSearch: Date | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllRozwaga`, {
      pageNumber: paginationModel.page,
      itemsOnPage: paginationModel.pageSize,
      searchByOwnerId: searchByOwnerId !== null && searchByOwnerId !== undefined ? searchByOwnerId : null,
      searchByCreationDateFrom: dateFromValueForSearch !== null && dateFromValueForSearch !== undefined ? dateToJson(dateFromValueForSearch) : null,
      searchByCreationDateTo: dateToValueForSearch !== null && dateToValueForSearch !== undefined ? dateToJson(dateToValueForSearch) : null
    }, { headers: CustomHeaders() });
};

export const createRozwagaApi = (
  dostawaDetailId: number,
  quantity: number,
  note: string) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/CreateRozwaga`, {
      dostawaDetailId: dostawaDetailId,
      quantity: quantity,
      note: note
    }, { headers: CustomHeaders() });
};

export const removeRozwagaApi = (rozwagaId: number) => {
  let query = `rozwagaId=${rozwagaId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}MagazynActions/RemoveRozwaga?${query}`, { headers: CustomHeaders() });
};



export const getAllDostawcaForRozchodApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllDostawcaForRozchod`, { headers: CustomHeaders() });
};

export const getAllRozchodApi = (
  paginationModel: {pageSize: number; page: number;},
  searchByOwnerId: number | null,
  dateFromValueForSearch: Date | null,
  dateToValueForSearch: Date | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllRozchod`, {
      pageNumber: paginationModel.page,
      itemsOnPage: paginationModel.pageSize,
      searchByOwnerId: searchByOwnerId !== null && searchByOwnerId !== undefined ? searchByOwnerId : null,
      searchByCreationDateFrom: dateFromValueForSearch !== null && dateFromValueForSearch !== undefined ? dateToJson(dateFromValueForSearch) : null,
      searchByCreationDateTo: dateToValueForSearch !== null && dateToValueForSearch !== undefined ? dateToJson(dateToValueForSearch) : null
    }, { headers: CustomHeaders() });
};

export const createRozchodApi = (
  dostawaDetailId: number,
  quantity: number,
  note: string) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/CreateRozchod`, {
      dostawaDetailId: dostawaDetailId,
      quantity: quantity,
      note: note
    }, { headers: CustomHeaders() });
};

export const removeRozchodApi = (rozchodId: number) => {
  let query = `rozchodId=${rozchodId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}MagazynActions/RemoveRozchod?${query}`, { headers: CustomHeaders() });
};



export const getAllDostawcaForPrzychodApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllDostawcaForPrzychod`, { headers: CustomHeaders() });
};

export const getAllPrzychodApi = (
  paginationModel: {pageSize: number; page: number;},
  searchByOwnerId: number | null,
  dateFromValueForSearch: Date | null,
  dateToValueForSearch: Date | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllPrzychod`, {
      pageNumber: paginationModel.page,
      itemsOnPage: paginationModel.pageSize,
      searchByOwnerId: searchByOwnerId !== null && searchByOwnerId !== undefined ? searchByOwnerId : null,
      searchByCreationDateFrom: dateFromValueForSearch !== null && dateFromValueForSearch !== undefined ? dateToJson(dateFromValueForSearch) : null,
      searchByCreationDateTo: dateToValueForSearch !== null && dateToValueForSearch !== undefined ? dateToJson(dateToValueForSearch) : null
    }, { headers: CustomHeaders() });
};

export const getDetailsOfPrzychodApi = (przychodId: number) => {
  let query = `przychodId=${przychodId}`;
  return axios.get(`${process.env.REACT_APP_API_URL}MagazynActions/GetDetailsOfPrzychod?${query}`, { headers: CustomHeaders() });
};

export const createPrzychodApi = (
  dostawaDetailId: number,
  quantity: number,
  note: string,
  listOfNewDetails: {towarId: number, quantity: number, price: number}[],
  pwOrZw: boolean
) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}MagazynActions/CreatePrzychod`, {
      dostawaDetailId: dostawaDetailId,
      quantity: quantity,
      note: note,
      listOfNewDetails: listOfNewDetails,
      withPriceValidation: pwOrZw
    }, { headers: CustomHeaders() });
};

export const removePrzychodApi = (przychodId: number) => {
  let query = `przychodId=${przychodId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}MagazynActions/RemovePrzychod?${query}`, { headers: CustomHeaders() });
};