import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { editWydanieApi } from '../../Services/WydanieService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { IWydanie } from '../../Models/Wydanie';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import { AppContext } from '../../App';

interface AdditionalDataWydanieModalProps {
	wydanie: IWydanie;
  setOpenAdditionalDataWydanieModal: Dispatch<SetStateAction<boolean>>;
}
function AdditionalDataWydanieModal({wydanie, setOpenAdditionalDataWydanieModal} : AdditionalDataWydanieModalProps) {
  const appContext = useContext(AppContext);
  const [loadingEditButton, setLoadingEditButton] = useState<boolean>(false);

  const [driverName, setDriverName] = useState<string | null>(wydanie.driverName);
  const [driverNumber, setDriverNumber] = useState<string | null>(wydanie.driverNumber);
  const [euroPalletCount, setEuroPalletCount] = useState<number | null>(wydanie.euroPalletCount);
  const [normalPalletCount, setNormalPalletCount] = useState<number | null>(wydanie.normalPalletCount);

  const handleSetOpenAdditionalDataWydanieModal = useCallback((event: boolean) => {
    setOpenAdditionalDataWydanieModal(event);
  }, [setOpenAdditionalDataWydanieModal])

	const editAction = async () => {
		setLoadingEditButton(true);
		await editWydanieApi(
			wydanie.id,
			wydanie.dostawaDate,
			wydanie.odbiorcaId,
			wydanie.dostawcaNumber,
			wydanie.orderNumber,
			driverName,
			driverNumber,
			euroPalletCount,
			normalPalletCount)
			.then(
				async (response: AxiosResponse) => {
					wydanie.driverName = driverName;
					wydanie.driverNumber = driverNumber;
					wydanie.euroPalletCount = euroPalletCount;
					wydanie.normalPalletCount = normalPalletCount;
					await Debounce(setLoadingEditButton, false);
					handleSetOpenAdditionalDataWydanieModal(false);
					handleSuccess(response);
					return true;
				})
			.catch(
				(error: AxiosError) => {
					handleErros(error, appContext);
					return false;
				}
			);
  };

	return (
		<>
			<h1 style={{ margin: '0 0 20px 0' }}>Dodatkowe dane do wydania zewnętrznego</h1>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setDriverName(event.target.value)}}
						defaultValue={wydanie.driverName}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="DriverName"
						name="DriverName"
						label="Kierowca"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setDriverNumber(event.target.value)}}
						defaultValue={wydanie.driverNumber}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="DriverNumber"
						name="DriverNumber"
						label="Numer auta"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setEuroPalletCount(event.target.value === null || event.target.value === undefined || event.target.value === '' ? null : Number(event.target.value))}}
						defaultValue={wydanie.euroPalletCount}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="EuroPalletCount"
						name="EuroPalletCount"
						label="Euro palet"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setNormalPalletCount(event.target.value === null || event.target.value === undefined || event.target.value === '' ? null : Number(event.target.value))}}
						defaultValue={wydanie.normalPalletCount}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="NormalPalletCount"
						name="NormalPalletCount"
						label="Zwykłych palet"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingEditButton}
						loadingPosition="center"
						variant="contained"
						onClick={editAction} >
					</LoadingButton>
				</Grid>
			</Grid>
		</>
	)
};

export default AdditionalDataWydanieModal;