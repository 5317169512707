import { AxiosHeaders } from "axios";
import { enqueueSnackbar } from "notistack";
import { AppContextType } from "../App";
import { read_cookie, delete_cookie } from 'sfcookies';

export function CustomHeaders(contentType: string | null = null) {
  const headers = new AxiosHeaders();
  
  //const token = localStorage.getItem("JWT");
  const token = read_cookie("JWT");
  if (token) {
    headers.set('Authorization', 'Bearer ' + token);
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  }

  if (contentType) {
    headers.set('content-type', contentType);
  }

  return headers;
}

export function handleErros(error: any, appContext: AppContextType | null) {
  console.log(error);

  if (error.response && error.response.status === 401) {
    //localStorage.removeItem('JWT');
    delete_cookie('JWT');
    appContext?.updateIsLoggedIn(false);
    enqueueSnackbar('Proszę zalogować sie ponownie.', { variant: 'warning' });
    // if (window.location.href.indexOf('refresh') === -1 && window.location.href.indexOf('wrongData') === -1) {
    //   window.location.replace(window.location.href + '?refresh');
    // }
    // else if (window.location.href.indexOf('refresh') !== -1) {
    //   window.location.replace(window.location.href.replace('?refresh', '?wrongData'));
    // }
    // else {
    //   window.location.reload();
    // }
  }

  if (error.response?.status === 500) {
    enqueueSnackbar('Wewnętrzny błąd serwera. Skontaktuj sie z administratorem.', { variant: 'error' });
  } else if (error.response?.status === 400) {
    if (error.response.data.message !== undefined) {
      enqueueSnackbar(`${error.response.data.message}`, { variant: 'error' });
    } else {
      enqueueSnackbar(`${error.response.data.errors.quantity}`, { variant: 'error' });
    }
  } else {
    enqueueSnackbar(`${error.message}`, { variant: 'error' });
  }
}

export function handleSuccess(response: any) {
  enqueueSnackbar(`${response.data}`, { variant: 'success' });
}