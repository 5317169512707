import { GridColDef } from "@mui/x-data-grid/models";

export const producentCodeColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Nazwa dostawcy',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 400,
        cellClassName: 'producentCodeFirstColumn',
        headerClassName: 'producentCodeFirstColumn'
    },
    {
        field: 'code',
        headerName: 'Kod producenta',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 110,
        headerAlign: "center",
        align: "center"
    }
];