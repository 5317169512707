import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import { getAllDostawcaForPrzychodApi, getAllPrzychodApi, removePrzychodApi } from '../Services/MagazynService';
import { IActionWithTowarDescription, actionWithTowarDescriptionColumns } from '../Models/MagazynActions';
import ModalElement from '../Features/Modal';
import { OpenPrzychodWewnetrznyModal } from '../SiteComponents/PrzychodyWewnetrznePage/OpenPrzychodWewnetrznyModal';
import { IdAndLabel } from '../Models/Supply';
import { PrzychodyRozchodyRozwagiFilter } from '../SiteComponents/PrzychodyRozchodyRozwagiFilter';
import { Grid } from '@mui/material';

function PrzychodyWewnetrznePagePage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [dostawcyOptions, setDostawcyOptions] = useState<IdAndLabel[]>([]);
  const [loadingAllPrzychod, setLoadingAllPrzychod] = useState<boolean>(false);
  const [loadingAllDostawca, setLoadingAllDostawca] = useState<boolean>(false);
  const [openPwDetailModal, setOpenPwDetailModal] = useState(false);
  const [doubleClickedPwRow, setDoubleClickedPwRow] = useState<IActionWithTowarDescription | null>(null);
  const [currentDostawcaId, setCurrentDostawcaId] = useState<number | null>(null);
  const [dateFromValueForSearch, setDateFromValueForSearch] = useState<Date | null>(null);
  const [dateToValueForSearch, setDateToValueForSearch] = useState<Date | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  
  const getAllDostawcaForPrzychod = useCallback(async () => {
    setLoadingAllDostawca(true);
    await getAllDostawcaForPrzychodApi().then(
    (response: AxiosResponse) => {
      setDostawcyOptions(response.data);
      setLoadingAllDostawca(false);
    },
    (error: AxiosError) => {
      handleErros(error, appContext);
      setLoadingAllDostawca(false);
    });
  }, [appContext]);

  const getAllPrzychod = useCallback(async () => {
    setLoadingAllPrzychod(true);
    await getAllPrzychodApi({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId, dateFromValueForSearch, dateToValueForSearch).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setLoadingAllPrzychod(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllPrzychod(false);
      });
  }, [currentDostawcaId, dateFromValueForSearch, dateToValueForSearch, page, appContext]);

  const initialize = useCallback(async () => {
    getAllDostawcaForPrzychod();
    getAllPrzychod();
  }, [getAllDostawcaForPrzychod, getAllPrzychod]);
  useEffect(() => { initialize(); }, [initialize]);
  
  const deletePrzychodAction = async (row: any) => {
    setLoadingAllPrzychod(true);
    await removePrzychodApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingAllPrzychod(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllPrzychod(false);
      }
    );
  };
  
  const doubleClickWydanieDetailRowAction = async (row: IActionWithTowarDescription) => {
    setDoubleClickedPwRow(row);
    setOpenPwDetailModal(true);
  };

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Przychody/Zmiany Wewnętrzne</h1>
      <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
        sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
        <PrzychodyRozchodyRozwagiFilter
          dostawcyOptions={dostawcyOptions}
          loadingAllDostawca={loadingAllDostawca}
          setCurrentDostawcaId={setCurrentDostawcaId}
          setDateFromValueForSearch={setDateFromValueForSearch}
          setDateToValueForSearch={setDateToValueForSearch}
        />
      </Grid>
      <EditableGrid
        columns={actionWithTowarDescriptionColumns}
        rows={rows}
        editAction={async () => false}
        loading={loadingAllPrzychod}
        setPaginationModel={paginationModelChange}
        paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
        total={total}
        setQueryOptions={() => null}
        doubleClickRowAction={(row: IActionWithTowarDescription) => {doubleClickWydanieDetailRowAction(row)}}
        deleteAction={deletePrzychodAction}
        hideDeleteAction={false}
        hideEditAction={true}
        hideActionColumn={false}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        setRowsSelectionModel={() => null}
        rowSelectionModel={undefined}
        hideFooter={false}
        hideFooterSelectedRowCount={false}
        columnVisibilityModel={{}}
        setColumnVisibilityModel={() => null}
        columnGroupingModel={undefined}
        additionalStyles={undefined}
      />
      <ModalElement handleClose={() => { setOpenPwDetailModal(false); initialize(); }} open={openPwDetailModal} centerCloseButton={false} additionalStyles={{top: '7% !important'}}
        modalContent={
          <OpenPrzychodWewnetrznyModal doubleClickedPwRow={doubleClickedPwRow!} />
        }
      />
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default PrzychodyWewnetrznePagePage;