import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { Autocomplete, darken, Grid, lighten, styled, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { BoxType, ICreateWydanieDetail, IWydanie } from '../../Models/Wydanie';
import { enqueueSnackbar } from 'notistack';
import { createWydanieDetailApi } from '../../Services/WydanieService';
import { AxiosResponse, AxiosError } from 'axios';
import { handleSuccess, handleErros } from '../../Helpers/AxiosCustomHeaders';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import { AppContext } from '../../App';

interface AddWydanieDetailModalProps {
	wydanie: IWydanie;
  optionsTowary: any[];
  setOpenAddDetailModal: Dispatch<SetStateAction<boolean>>;
}
function AddWydanieDetailModal({ wydanie, optionsTowary, setOpenAddDetailModal } : AddWydanieDetailModalProps) {
  const appContext = useContext(AppContext);
  const options = Object.values(BoxType);
  const [newWydanieDetail, setNewWydanieDetail] = useState<ICreateWydanieDetail>({wydanieId: wydanie.id, comesFromCountry: "PL", boxType: options[0]} as ICreateWydanieDetail);
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
	
  const handleSetOpenAddModal = useCallback((event: boolean) => {
    setOpenAddDetailModal(event);
  }, [setOpenAddDetailModal])
	
  const addAction = async () => {
    if (newWydanieDetail.towarId === null || newWydanieDetail.towarId === undefined ||
			newWydanieDetail.boxType === null || newWydanieDetail.boxType === undefined ||
			newWydanieDetail.comesFromCountry === null || newWydanieDetail.comesFromCountry === "" || newWydanieDetail.comesFromCountry === undefined ||
			newWydanieDetail.numberOfBoxes === null || newWydanieDetail.numberOfBoxes === undefined ||
			newWydanieDetail.wydanieId === null || newWydanieDetail.wydanieId === undefined
    ) {
      enqueueSnackbar('Proszę uzupełnić wszystkie pola.', { variant: 'info' });
    } else {
      setLoadingCreateButton(true);
      await createWydanieDetailApi(newWydanieDetail).then(
        async (response: AxiosResponse) => {
					await Debounce(setLoadingCreateButton, false);
          handleSetOpenAddModal(false);
          handleSuccess(response);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingCreateButton(false);
        }
      )
    }
  };

	return (
		<>
			<h1 style={{ margin: '0 0 20px 0' }}>Dodaj detal dla wydania zewnętrznego</h1>
			<Grid container spacing={0}>
				<Grid item xs={12} sx={{mb: '4px'}}>
					{<Autocomplete  // rerender modal each time because in autocomplete there is no value defined so choosen elements stay as picked but we are removing them to not choose second time
						defaultValue={null}
						options={optionsTowary}
						onChange={(event: any, newValue: any, reason, details) => {
							setNewWydanieDetail({ ...newWydanieDetail, towarId: newValue.key })
						}}
						groupBy={(option) => option.nazwaGrupy}
						classes={{ paper: "optionsForTowaryForDostawca" }}
						renderGroup={(params) => (
							<li key={params.key}>
								<GroupHeader onClick={() => {
									(document.getElementById("groupItems" + params.key)!).style.display === "none" ?
									(document.getElementById("groupItems" + params.key)!).style.display = "block" :
									(document.getElementById("groupItems" + params.key)!).style.display = "none";
									}}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>
								<GroupItems id={"groupItems" + params.key} style={{display: "none"}}>{params.children}</GroupItems>
							</li>
						)}
						renderOption={(props, option: any, { selected }) => (
							<li {...props} key={option.id} style={{paddingBottom: 6, paddingTop: 6}}>
								{option.label}
							</li>
						)}
						renderInput={(params) => ( <TextField {...params} label={`Wybierz towar *`} /> )}
					/>}
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						fullWidth
						options={options}
						onChange={(_, data) => { setNewWydanieDetail({ ...newWydanieDetail, boxType: data! });}}
						defaultValue={options[0]}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								margin='dense'
								required
								id="boxType"
								name="boxType"
								label="Rodzaj skrzynki"
								size="medium"
								autoComplete="cc-number"
							/>
						)}
						clearIcon={null}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setNewWydanieDetail({ ...newWydanieDetail, numberOfBoxes: Number(event.target.value) })}}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="numberOfBoxes"
						name="numberOfBoxes"
						label="Liczba opakowań"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						value={newWydanieDetail.comesFromCountry}
						onChange={(event: { target: { value: string }}) => {setNewWydanieDetail({ ...newWydanieDetail, comesFromCountry: event.target.value })}}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="comesFromCountry"
						name="comesFromCountry"
						label="Kraj pochodzenia"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingCreateButton}
						loadingPosition="center"
						variant="contained"
						onClick={addAction} >
					</LoadingButton>
				</Grid>
			</Grid>
		</>
	)
};

export default AddWydanieDetailModal;

const GroupHeader = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	zIndex: 1,
	color: theme.palette.primary.main,
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 0.85)
			: darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled('ul')({
	padding: 0,
});