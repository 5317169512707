import { GridColDef } from "@mui/x-data-grid/models";
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export interface IWydanie {
  id: number,
  odbiorca: string,
  odbiorcaId: number,
  number: string,
  dostawaDate: Date,
  dostawcaNumber: string,
  orderNumber: string,
  driverName: string | null,
  driverNumber: string | null,
  euroPalletCount: number | null,
  normalPalletCount: number | null,
  createdDate: Date,
  completed: boolean
}

export const wydanieColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'odbiorca',
    headerName: 'Odbiorca',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 290,
    disableColumnMenu: true
  },
  {
    field: 'number',
    headerName: 'Numer',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 80,
    disableColumnMenu: true
  },
  {
    field: 'completed',
    headerName: 'Zatwier-dzone',
    type: 'boolean',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 85,
    align: "center"
  },
  {
    field: 'dostawaDate',
    headerName: 'Data dostawy',
    type: 'date',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 120,
    valueGetter: ( value ) => value && new Date(value)
  },
  {
    field: 'dostawcaNumber',
    headerName: 'Numer dostawcy',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 130,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'orderNumber',
    headerName: 'Numer zamówienia',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 130,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'driverName',
    headerName: 'Kierowca',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 140,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'driverNumber',
    headerName: 'Numer auta',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'euroPalletCount',
    headerName: 'Euro palet',
    type: 'number',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 90,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'normalPalletCount',
    headerName: 'Zwykłych palet',
    type: 'number',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 90,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'createdDate',
    headerName: 'Utworzono',
    type: 'dateTime',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 170,
    valueGetter: ( value ) => value && new Date(value)
  }
];

export interface IWydanieDetail {
  id: number,
  towarName: string,
  towarId: number,
  comesFromCountry: string,
  boxType: string,
  numberOfBoxes: number,
  count: number | null
}
export interface ICreateWydanieDetail {
  wydanieId: number,
  towarId: number,
  comesFromCountry: string,
  boxType: BoxType,
  numberOfBoxes: number
}
export enum BoxType {
  threeKg = "3kg",
  epsOneZeroFour = "EPS104",
  epsOneThreeSix = "EPS136",
  carton = "karton",
  tenKg = "10kg"
}

export const wydanieDetailsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'towarName',
    headerName: 'Nazwa towaru',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 310,
    disableColumnMenu: true
  },
  {
    field: 'comesFromCountry',
    headerName: 'Kraj',
    editable: true,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 60,
    disableColumnMenu: true
  },
  {
    field: 'boxType',
    headerName: 'Rodzaj skrzynki',
    type: 'singleSelect',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 120,
    headerAlign: "center",
    align: "center",
    valueOptions: Object.values(BoxType)
  },
  {
    field: 'numberOfBoxes',
    headerName: 'Liczba opakowań',
    type: 'number',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 100,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'count',
    headerName: 'Ilość kg/szt',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 70,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'status',
    headerName: '',
    editable: false,
    sortable: false,
    hideable: false,
    filterable: false,
    width: 55,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      if (params.row.status === 0)
        return <ErrorIcon sx={{ color: 'darkred', marginTop: '13px', verticalAlign: 'top' }} />
      else if (params.row.status === 1)
        return <PendingIcon sx={{ color: 'darksalmon', marginTop: '13px', verticalAlign: 'top' }}/>
      else if (params.row.status === 2)
        return <CheckCircleIcon sx={{ color: 'green', marginTop: '13px', verticalAlign: 'top' }}/>
      else
        return <ErrorIcon sx={{ color: 'darkred', marginTop: '13px', verticalAlign: 'top' }}/>
    }
  },
];









export interface IWydanieHistory {
  id: number,
  odbiorca: string,
  odbiorcaId: number,
  number: string,
  dostawaDate: Date,
  dostawcaNumber: string,
  orderNumber: string,
  driverName: string | null,
  driverNumber: string | null,
  euroPalletCount: number | null,
  normalPalletCount: number | null,
  createdDate: Date,
  completed: boolean,
  wydanieDetails: IWydanieDetailHistory[]
}

interface IWydanieDetailHistory {
  id: number,
  towarId: number,
  towarName: string,
  comesFromCountry: string,
  boxType: string,
  numberOfBoxes: number,
  count: number,
  status: number,
  doneZwrot: boolean,
  magazynToWydanieDetails: IWydanieDetailToMagazynHistory[]
}

interface IWydanieDetailToMagazynHistory {
  id: number,
  towarName: string,
  dostawca: string,
  quantity: number,
  price: number,
  sumNetto: number,
  additionalInfo: string,
  numberPZ: string,
  createdDate: string,
  dostawaId: number,
  dostawaDetailId: number,
  fromPw: boolean,
  fromPwWithoutPriceValidation: boolean,
  donePw: boolean,
  donePwWithoutPriceValidation: boolean,
  doneRw: boolean,
  doneRozwaga: boolean,
  barcode: string,
  doneZwrot: boolean,
  dostawaDetail: IMagazynDostawaDetailHistory
}

export interface IMagazynDostawaDetailHistory {
  towarName: string,
  additionalInfo: string,
  transformationNote: string,
  dostawaDetail: IMagazynDostawaDetailHistory
}