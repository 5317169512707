import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Autocomplete, Checkbox, Grid, TextField, Typography, darken, lighten, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { createPrzychodApi, createRozchodApi, createRozwagaApi } from '../../Services/MagazynService';
import { getAllTowaryApi } from '../../Services/SupplyService';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditableGrid from '../../Features/EditableGrid';
import { IPrzychodWewnetrzny, przychodWewnetrznyColumns } from '../../Models/Magazyn';
import { TowaryApi } from '../../Models/Supply';
import SouthIcon from '@mui/icons-material/South';
import { v4 as uuidv4 } from 'uuid';
import Decimal from 'decimal.js';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import { MaterialUISwitch } from '../../Features/Switch';
import { AppContext } from '../../App';

interface OpenMagazynModalProps {
	doubleClickedMagazynRow: any;
  closeMagazynModalAction: Dispatch<void>;
}
export function OpenMagazynModal({ doubleClickedMagazynRow, closeMagazynModalAction }: OpenMagazynModalProps) {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<IPrzychodWewnetrzny[]>([]); // magazyn(MagazynToWzDetail)
  const [loadingCreateRozwagaButton, setloadingCreateRozwagaButton] = useState<boolean>(false);
  const [howManyRozwaga, setHowManyRozwaga] = useState<string>("");
  const [noteRozwaga, setNoteRozwaga] = useState<string>("");
  const [loadingCreateRozchodButton, setloadingCreateRozchodButton] = useState<boolean>(false);
  const [howManyRozchod, setHowManyRozchod] = useState<string>("");
  const [noteRozchod, setNoteRozchod] = useState<string>("");
  const [loadingCreatePrzychodButton, setloadingCreatePrzychodButton] = useState<boolean>(false);
  const [howManyPrzychod, setHowManyPrzychod] = useState<string>("");
  const [notePrzychod, setNotePrzychod] = useState<string>("");
  //const [rerenderAutocomplete, setRerenderAutocomplete] = useState(0);
  const [priceSumDiferenceMessage, setPriceSumDiferenceMessage] = useState<string>("");
  const [maxQuantityToUseMessage, setMaxQuantityToUseMessage] = useState<string>("");
  const [pwOrZw, setPwOrZw] = useState<boolean>(false);

  const handleCloseMagazynModal = useCallback(() => {
    closeMagazynModalAction();
  }, [closeMagazynModalAction])

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [optionsTowary, setOptionsTowary] = useState<TowaryApi[]>([]);
  let myState = useRef<TowaryApi[]>([]); // prevent state from update - thanks to that autocomplete is not re-rendering each time after choosing option

  const initialize = useCallback(async () => {
		setMaxQuantityToUseMessage(doubleClickedMagazynRow.quantity);
		await getAllTowaryApi(true).then(
      async (response: AxiosResponse) => {
        setOptionsTowary(response.data);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      });
  }, [doubleClickedMagazynRow.quantity, appContext]);
	useEffect(() => { initialize(); }, [initialize]);

	useEffect(() => {
		if (howManyPrzychod !== "" && !isNaN(Number(howManyPrzychod))) {
			if (Number(howManyPrzychod) <= Number(doubleClickedMagazynRow.quantity)) {
				if (!pwOrZw) {
					let temp = new Decimal(0);
					rows.forEach(x => {
						if (x.price === null || x.price === undefined || x.quantity === null || x.quantity === undefined) {
							return;
						}
						let xx = new Decimal(x.quantity);
						let xxx = new Decimal(x.price);
						temp = new Decimal(xx.times(xxx).add(temp).toFixed(2));
					});
					let t = new Decimal(howManyPrzychod);
					let tt = new Decimal(doubleClickedMagazynRow.price);
					temp = new Decimal(t.times(tt).toFixed(2)).minus(temp);
					if (temp.eq(0)) {
						setPriceSumDiferenceMessage(``);
					} else {
						setPriceSumDiferenceMessage(`Kwota do pokrycia: ${t.times(tt).toFixed(2)} zł. Do uzupełnienia zostaje: ${temp.toFixed(2)} zł.`);
					}
				} else {
					setPriceSumDiferenceMessage(``);
				}
			} else {
				setPriceSumDiferenceMessage(`Nie można pobrać wiecej niż jest w magazynie.`);
			}
		} else {
			setPriceSumDiferenceMessage(`Uzupełnij ilość do zrobienia ${!pwOrZw ? 'przychodu wewnętrznego' : 'zmiany wewnetrznej'}.`);
		}
	}, [rows, howManyPrzychod, doubleClickedMagazynRow.quantity, doubleClickedMagazynRow.price, pwOrZw]);

  const addRozwagaAction = async () => {
		if (Number(howManyRozwaga) > Number(doubleClickedMagazynRow.quantity)) {
      enqueueSnackbar('Nie można pobrać wiecej niż jest w magazynie.', { variant: 'info' });
		} else if (howManyRozwaga === null || howManyRozwaga === undefined || Number(howManyRozwaga) === 0) {
      enqueueSnackbar('Proszę uzupełnić ilość do rozwagi.', { variant: 'info' });
    } else {
      setloadingCreateRozwagaButton(true);
      await createRozwagaApi(doubleClickedMagazynRow.dostawaDetailId, Number(howManyRozwaga), noteRozwaga).then(
        async (response: AxiosResponse) => {
					await Debounce(setloadingCreateRozwagaButton, false);
          handleSuccess(response);
					handleCloseMagazynModal();
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setloadingCreateRozwagaButton(false);
        }
      )
    }
  };

	const addRozchodAction = async () => {
		if (Number(howManyRozchod) > Number(doubleClickedMagazynRow.quantity)) {
      enqueueSnackbar('Nie można pobrać wiecej niż jest w magazynie.', { variant: 'info' });
		} else if (howManyRozchod === null || howManyRozchod === undefined || Number(howManyRozchod) === 0) {
      enqueueSnackbar('Proszę uzupełnić ilość do rozchodu.', { variant: 'info' });
    } else {
      setloadingCreateRozchodButton(true);
      await createRozchodApi(doubleClickedMagazynRow.dostawaDetailId, Number(howManyRozchod), noteRozchod).then(
        async (response: AxiosResponse) => {
					await Debounce(setloadingCreateRozchodButton, false);
          handleSuccess(response);
					handleCloseMagazynModal();
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setloadingCreateRozchodButton(false);
        }
      )
    }
  };

	const addToPrzychodAction = () => {
		setRows(rows.concat(myState.current.map(x => { return { towarId: x.key, towarName: x.label, id: uuidv4() } as IPrzychodWewnetrzny})));
		//setRerenderAutocomplete(rerenderAutocomplete + 1);
  };

	const confirmPrzychodAction = async () => {
		if (howManyPrzychod === null || howManyPrzychod === undefined || Number(howManyPrzychod) === 0 || notePrzychod === null || notePrzychod === undefined || notePrzychod === '') {
      enqueueSnackbar('Proszę uzupełnić ilość do pobrania oraz uwagi.', { variant: 'info' });
    } else if (priceSumDiferenceMessage !== "") {
      enqueueSnackbar('Proszę uzupełnić poprawnie ilości nowych towarów i ich ceny.', { variant: 'info' });
    } else {
			let temp = true;
			rows.forEach(x => {
				if (x.price === null || x.price === undefined || x.quantity === null || x.quantity === undefined) {
					temp = false;
					enqueueSnackbar('Proszę uzupełnić poprawnie ilości nowych towarów i ich ceny.', { variant: 'info' });
					return;
				}
			});
			if (temp) {
				setloadingCreatePrzychodButton(true);
				await createPrzychodApi(doubleClickedMagazynRow.dostawaDetailId, Number(howManyPrzychod), notePrzychod,
					rows.map(x => { return {towarId: x.towarId, quantity: x.quantity, price: x.price}; }), !pwOrZw).then(
					async (response: AxiosResponse) => {
						await Debounce(setloadingCreatePrzychodButton, false);
						handleSuccess(response);
						handleCloseMagazynModal();
					},
					(error: AxiosError) => {
						handleErros(error, appContext);
						setloadingCreatePrzychodButton(false);
					}
				)
			}
    }
  };
	
  const deleteFromPrzychodAction = async (row: IPrzychodWewnetrzny) => {
		setRows(rows.filter(x => x.id !== row.id));
  };

	const editAction = async (newRow: IPrzychodWewnetrzny, oldRow: IPrzychodWewnetrzny) : Promise<boolean> => {
		setRows(rows.map(x => {
			if (x.id === newRow.id) {
				if (newRow.price !== null && newRow.price !== undefined && newRow.quantity !== null && newRow.quantity !== undefined) {
					let xx = new Decimal(newRow.quantity);
					let xxx = new Decimal(newRow.price);
					x.quantity = Number(xx.toFixed(2));
					x.price = Number(xxx.toFixed(2));
					x.sum = Number(xx.times(xxx).toFixed(2));
					return x;
				} else {
					x.quantity = newRow.quantity;
					x.price = newRow.price;
					x.sum = 0;
					return x;
				}
			} else {
				return x;
			}
	 	}));
		return true;
  };

	function MaxQuantityToUseMessage() {
		return <Typography variant="subtitle2" sx={{marginTop: '-5px', fontSize: '12px'}} component="div">{doubleClickedMagazynRow.towarName} - Cena: {doubleClickedMagazynRow.price} - Do dyspozycji: {maxQuantityToUseMessage}</Typography>;
	}

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} variant="fullWidth" scrollButtons allowScrollButtonsMobile onChange={handleChange} centered>
          <Tab label="Rozwaga" {...a11yProps(0)} />
          <Tab label="Rozchód Wewnętrzny" {...a11yProps(1)} />
          <Tab label="Przychód/Zmiana wewnetrzna" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} additionalStyles={undefined}>
      	<h1 style={{ margin: '0px 0 20px 0' }}>Rozwaga<MaxQuantityToUseMessage /></h1>
				<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}>
					<Grid item xs={12}>
						<TextField
							label={"Ile"}
							onChange={(event: { target: { value: string }}) => {setHowManyRozwaga(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="number"
							name="number"
							type="number"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={"Uwagi"}
							onChange={(event: { target: { value: string }}) => {setNoteRozwaga(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="note"
							name="note"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton sx={{ width: 1, height: '56px', mt: 3, mb: 0 }}
							endIcon={<UploadIcon style={{ fontSize: '30px' }}/>}
							loading={loadingCreateRozwagaButton}
							loadingPosition="center"
							variant="contained"
							onClick={addRozwagaAction} >
						</LoadingButton>
					</Grid>
				</Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} additionalStyles={undefined}>
      	<h1 style={{ margin: '0px 0 20px 0' }}>Rozchód wewnętrzny<MaxQuantityToUseMessage /></h1>
				<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}>
					<Grid item xs={12}>
						<TextField
							label={"Ile"}
							onChange={(event: { target: { value: string }}) => {setHowManyRozchod(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="number"
							name="number"
							type="number"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={"Uwagi"}
							onChange={(event: { target: { value: string }}) => {setNoteRozchod(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="note"
							name="note"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton sx={{ width: 1, height: '56px', mt: 3, mb: 0  }}
							endIcon={<UploadIcon style={{ fontSize: '30px' }}/>}
							loading={loadingCreateRozchodButton}
							loadingPosition="center"
							variant="contained"
							onClick={addRozchodAction} >
						</LoadingButton>
					</Grid>
				</Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} additionalStyles={{maxWidth: '800px'}}>
				<Box sx={{ mt: '-20px', mb: '-5px' }}>
					PW<MaterialUISwitch checked={pwOrZw} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPwOrZw(event.target.checked)}} inputProps={{ 'aria-label': 'controlled' }} />ZW</Box>
      	<h1 style={{ margin: '0px 0 20px 0' }}>{!pwOrZw ? 'Przychód wewnetrzny' : 'Zmiana wewnetrzna'}<MaxQuantityToUseMessage /></h1>
				<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}>
					<Grid item xs={12}>
						<TextField
							label={"Ile"}
							onChange={(event: { target: { value: string }}) => {setHowManyPrzychod(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="number"
							name="number"
							type="number"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12} sx={{mb: '20px'}}>
						<TextField
							label={"Uwagi"}
							onChange={(event: { target: { value: string }}) => {setNotePrzychod(event.target.value)}}
							variant="outlined"
							margin='none'
							required
							fullWidth
							id="note"
							name="note"
							size="medium"
							autoComplete="cc-number"
						/>
					</Grid>
					<Grid item xs={12} sx={{mb: '0px'}}>
						{<Autocomplete  // rerender modal each time because in autocomplete there is no value defined so choosen elements stay as picked but we are removing them to not choose second time
								multiple
								//key={rerenderAutocomplete}
								defaultValue={[]}
								options={optionsTowary}
								disableCloseOnSelect
								onChange={(event: any, newValue: TowaryApi[], reason, details) => {
									myState.current = newValue;
								}}
								groupBy={(option) => option.nazwaGrupy}
								classes={{ paper: "optionsForTowaryForDostawca" }}
								renderGroup={(params) => (
									<li key={params.key}>
										<GroupHeader onClick={() => {
											(document.getElementById("groupItems" + params.key)!).style.display === "none" ?
											(document.getElementById("groupItems" + params.key)!).style.display = "block" :
											(document.getElementById("groupItems" + params.key)!).style.display = "none";
											}}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>
										<GroupItems id={"groupItems" + params.key} style={{display: "none"}}>{params.children}</GroupItems>
									</li>
								)}
								renderOption={(props, option: any, { selected }) => (
									<li {...props} key={option.id} style={{paddingBottom: 6, paddingTop: 6}}>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											style={{ marginRight: 8 }}
											checked={selected}
										/>
										{option.label}
									</li>
								)}
								renderInput={(params) => ( <TextField {...params} label={`Wybierz towary i dodaj do ${!pwOrZw ? 'przychodu wewnętrznego' : 'zmiany wewnetrznej'}`} /> )}
							/>}
					</Grid>
					<Grid item xs={12}>
						<LoadingButton sx={{ width: 1, height: '56px', mt: 0, mb: 0  }}
							startIcon={<SouthIcon style={{ fontSize: '30px' }}/>}
							endIcon={<SouthIcon style={{ fontSize: '30px' }}/>}
							loadingPosition="center"
							variant="contained"
							onClick={addToPrzychodAction} >Dodaj do {!pwOrZw ? 'przychodu wewnętrznego' : 'zmiany wewnetrznej'}
						</LoadingButton>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle2" sx={{color: 'darkred'}} component="div">{priceSumDiferenceMessage}</Typography>
						<EditableGrid
							columns={przychodWewnetrznyColumns}
							rows={rows}
							editAction={editAction}
							loading={false}
							setPaginationModel={() => null}
							paginationModel={{pageSize: 100, page: 0}}
							total={0}
							setQueryOptions={() => null}
							doubleClickRowAction={() => null}
							deleteAction={deleteFromPrzychodAction}
							hideDeleteAction={false}
							hideEditAction={false}
							hideActionColumn={false}
							checkboxSelection={false}
							disableRowSelectionOnClick={false}
							setRowsSelectionModel={() => null}
							rowSelectionModel={undefined}
							hideFooter={true}
							hideFooterSelectedRowCount={true}
							columnVisibilityModel={{}}
							setColumnVisibilityModel={() => null}
							columnGroupingModel={undefined}
							additionalStyles={{width: '100%'}}
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton sx={{ width: 1, height: '56px', mt: 0, mb: 0  }}
							endIcon={<UploadIcon style={{ fontSize: '30px' }}/>}
							loading={loadingCreatePrzychodButton}
							loadingPosition="center"
							variant="contained"
							onClick={confirmPrzychodAction} >Zakończ
						</LoadingButton>
					</Grid>
				</Grid>
      </CustomTabPanel>
    </Box>
  );
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	additionalStyles: React.CSSProperties | undefined;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, additionalStyles, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={additionalStyles}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const GroupHeader = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	zIndex: 1,
	color: theme.palette.primary.main,
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 0.85)
			: darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled('ul')({
	padding: 0,
});