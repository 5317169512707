import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../Features/EditableGrid';
import { Autocomplete, Button, Grid, IconButton, TextField } from '@mui/material';
import ModalElement from '../Features/Modal';
import { getVatKeyByValue, supplyColumns, supplyDetailsColumns, Vat_enum } from '../Models/Supply';
import { createDostawa, editDostawaDetails, getDostawcyApi, getAllDostawy, getDostawaDetails, removeDostawa, removeSupplyDetail, sendDostawaToSPGJApi, approveDostawaApi, getTowaryForDostawcaApi, getAllTowaryExceptApi, getUrlToPrintPzTemplate } from '../Services/SupplyService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AddDetailsModal from '../SiteComponents/SuppliersPage/AddDetailsModal';
import PrintIcon from '@mui/icons-material/Print';
import { Debounce } from '../Helpers/ButtonDebouncing';
import { BarcodeColumn } from '../SiteComponents/AdditionalColumns';
import SplitButton, { SplitButtonOptionProps } from '../Features/SplitButton';

function SuppliersPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [currentDostawcaId, setCurrentDostawcaId] = useState<number | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [vatSetOnUser, setVatSetOnUser] = useState<boolean>(false);
  const [anyDetailWithVat, setAnyDetailWithVat] = useState<boolean>(false);
  const [rowsDetails, setRowsDetails] = useState([]);
  const [totalDetails, setTotalDetails] = useState<number>(0);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [loadingInitializationDetails, setLoadingInitializationDetails] = useState<boolean>(true);
  const [loadingButtons, setLoadingButtons] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
    initialize(param, currentDostawcaId);
  };
  
  const [pageDetails, setPageDetails] = useState(0);
  const paginationModelDetailsChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPageDetails(param.page);
    initializeDetails(param, currentDostawa?.id, compare);
  };
  const [currentDostawa, setCurrentDostawa] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFilteredOptionsTowary([]);
    const timeoutID = window.setTimeout(() => {setCompare(false);}, 100); // buttons flashing during modal closing
    return () => window.clearTimeout(timeoutID);
  }
  useEffect(() => { setPageDetails(0);}, [open]);

  const [forceResetOfDetailsItemAddForm, setForceResetOfDetailsItemAddForm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const handleOpenAddDetails = () => setOpenDetails(true);
  const handleCloseAddDetails = () => {setOpenDetails(false); setForceResetOfDetailsItemAddForm(!forceResetOfDetailsItemAddForm);}
  const [compare, setCompare] = useState(false);
  const handleCompare = async (value: boolean) => {await initializeDetails({pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}, currentDostawa.id, value); setCompare(value);}
    
  const initialize = useCallback(async (paginationModel: {pageSize: number; page: number;}, dostawcaId: number | null) => {
    setLoadingInitialization(true);
    
    if (appContext?.userData?.userRole === UserRole.dostawca && dostawcaId === null)
    {
      dostawcaId = Number(appContext?.userData?.userId);
      setCurrentDostawcaId(dostawcaId);
    }

    await getAllDostawy(paginationModel, dostawcaId).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setLoadingInitialization(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    )
  }, [appContext]);
  useEffect(() => { initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId); }, [appContext?.rowsNumberPageModel, currentDostawcaId, initialize, page]);

  const initializeDetails = async (paginationModelDetails: {pageSize: number; page: number;}, dostawaId: number, compare: boolean) => {
    setLoadingInitializationDetails(true);
    await getDostawaDetails(paginationModelDetails, dostawaId, compare).then(
      async (response: AxiosResponse) => {
        setRowsDetails(response.data.results);
        setTotalDetails(response.data.total);
        setVatSetOnUser(response.data.vatSetOnUser);
        setAnyDetailWithVat(response.data.anyDetailWithVat);
        setLoadingInitializationDetails(false);
      },
      (error: any) => {
        setCompare(false);
        setLoadingInitializationDetails(false);
        handleErros(error, appContext);
      }
    )
  };
  
  const handleCreateSupply = async () => {
    setLoadingButtons(true);
    await createDostawa(currentDostawcaId).then(
      async (response: AxiosResponse) => {
        handleSuccess(response);
				await Debounce(setLoadingButtons, false);
        initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingButtons(false);
      }
    )
  };

  const handleSendToSPGJ = async () => {
    setLoadingButtons(true);
    await sendDostawaToSPGJApi(currentDostawa?.id).then(
      async (response: AxiosResponse) => {
				await Debounce(setLoadingButtons, false);
        handleSuccess(response);
        handleClose();
        initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId);
      },
      (error: any) => {
        handleErros(error, appContext);
        setLoadingButtons(false);
      }
    )
  };

  const handleSendToRAKS = async (skippAddPzToRaks: boolean) => {
    setLoadingButtons(true);
    await approveDostawaApi(currentDostawa?.id, skippAddPzToRaks).then(
      async (response: AxiosResponse) => {
				await Debounce(setLoadingButtons, false);
        handleSuccess(response);
        handleClose();
        initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId);
      },
      (error: any) => {
        handleErros(error, appContext);
        setLoadingButtons(false);
      }
    )
  };
  
  const doubleClickRowAction = async (row: any) => {
    setCurrentDostawa(row);
    await initializeDetails({pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}, row.id, false);
    //if (!choosenDostawca) {
      await getTowaryForDostawca(row.ownedById, row.id);
    //}
    
    if (appContext?.userData?.userRole !== UserRole.dostawca) {
      await getAllTowaryEcxept(row.id, false);
    }
    handleOpen();
  };

  const editAction = async (newRow: any, oldRow: any) : Promise<boolean> => {
    return await editDostawaDetails(
      newRow.id,
      newRow.quantity,
      currentDostawa.id,
      newRow.vat_WartoscStawki != null ? Number(getVatKeyByValue(newRow.vat_WartoscStawki).replace('_', '')) || null : null,
      newRow.vat_WartoscStawki != null ? newRow.vat_WartoscStawki === Vat_enum._8 ? 0 : Number(newRow.vat_WartoscStawki) || null : null,
      newRow.price || null,
      newRow.additionalInfo || null)
      .then(
        (response: AxiosResponse) => {
          initializeDetails({pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}, currentDostawa?.id, false);
          handleSuccess(response);
          return true;
        })
      .catch(
        (error: AxiosError) => {
          handleErros(error, appContext);
          return false;
        }
      );
  };

  const [optionsTowary, setOptionsTowary] = useState<any>([]);
  //useEffect(() => { getTowaryForDostawca(null); }, [choosenDostawca]);
  const getTowaryForDostawca = async (idDostawca: number, idDostawa: number) => {
    // var data = choosenDostawca || idDostawca;
    // if (data) {
      await getTowaryForDostawcaApi(idDostawca, idDostawa).then(
        (response: AxiosResponse) => {
          setOptionsTowary(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    // } else {
    //   setOptionsTowary([]);
    // }
  };

  const [dostawcyOptions, setDostawcyOptions] = useState([]);
  const getDostawcy = useCallback(async () => {
    if (appContext?.userData?.userRole !== UserRole.dostawca) {
      await getDostawcyApi().then(
        (response: AxiosResponse) => {
          setDostawcyOptions(response.data)
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        }
      );
    }
  }, [appContext]);
  useEffect(() => { getDostawcy(); }, [getDostawcy]);
  
  const deleteSupplyAction = async (row: any) => {
    setLoadingInitialization(true);
    await removeDostawa(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const deleteSupplyDetailsAction = async (row: any) => {
    setLoadingInitializationDetails(true);
    await removeSupplyDetail(currentDostawa.id, row.id).then(
      async (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitializationDetails(false);
        initializeDetails({pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}, currentDostawa.id, false); 
        getTowaryForDostawca(currentDostawa.ownedById, currentDostawa.id);
        if (appContext?.userData?.userRole !== UserRole.dostawca) {
          getAllTowaryEcxept(currentDostawa.id, true);
        }
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitializationDetails(false);
      }
    );
  };

  const splitButtonOptions: SplitButtonOptionProps[] = [
    {buttonText: 'Wyślij do RAKSa', buttonColor : 'primary', action: async () => {await handleSendToRAKS(false)}, loadingButtons: loadingButtons},
    {buttonText: 'Potwierdź tylo w aplikacji, bez dodawania do RAKSa', buttonColor : 'error', action: async () => {await handleSendToRAKS(true)}, loadingButtons: loadingButtons},
  ];
  
  const modalContent = () => {
    return (<>
      <h1 style={{ margin: '0 0 20px 0' }}>Detale dostawy</h1>
      <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}} style={{marginTop: "-28px", marginBottom: "8px"}}>
        {(!compare) && (currentDostawa?.numberPZ === null) && (currentDostawa?.sendToSpgj === false || appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) &&
        <Grid item xs={12} sm={2} md={3} lg={3}>
          <IconButton onClick={handleOpenAddDetails} disabled={false} className='customAddLoadingButton' color={"primary"} >
            <AddIcon sx={{fontSize: "30px !important"}}/>
          </IconButton>
        </Grid>}
        {(rowsDetails.length > 0) && (!compare) && (currentDostawa?.numberPZ === null) && (currentDostawa?.sendToSpgj === false || appContext?.userData?.userRole === UserRole.admin) &&
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <LoadingButton sx={{ width: '100%', maxWidth: '250px' }}
            endIcon={currentDostawa?.sendToSpgj ? <GetAppIcon style={{fontSize: '30px'}}/> : <UploadIcon style={{fontSize: '30px'}}/>}
            loading={loadingButtons}
            loadingPosition="center"
            variant="contained"
            color={currentDostawa?.sendToSpgj ? "warning" : "primary"}
            onClick={handleSendToSPGJ} >{currentDostawa?.sendToSpgj ? "Cofnij z" : "Wyślij do"} SPGJ
          </LoadingButton>
        </Grid>}
        {(rowsDetails.length > 0) && (!compare) && (currentDostawa?.numberPZ === null) && (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) && (currentDostawa?.sendToSpgj === true) &&
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <SplitButton options={splitButtonOptions} />
        </Grid>}
        {(appContext?.userData?.userRole === UserRole.admin) && (currentDostawa?.sendToSpgj === true) &&
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <LoadingButton sx={{ width: '100%', maxWidth: '250px' }}
            endIcon={<CompareArrowsIcon style={{fontSize: '30px'}}/>}
            loading={loadingButtons}
            loadingPosition="center"
            variant={compare ? "outlined" : "contained"}
            onClick={async () => await handleCompare(!compare)} >{compare ? "Zamknij porównanie" : "Porównaj"}
          </LoadingButton>
        </Grid>}
        {(currentDostawa?.numberPZ !== null) &&
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <Button sx={{ width: '100%', maxWidth: '250px' }}
            endIcon={<PrintIcon style={{fontSize: '30px'}}/>}
            variant={"contained"}
            target='_blank'
            href={getUrlToPrintPzTemplate(currentDostawa?.id)} >Drukuj
          </Button>
        </Grid>}
      </Grid>
      <EditableGrid
        columns={
          appContext?.userData?.userRole === UserRole.dostawca ?
            supplyDetailsColumns.filter(x => x.field !== "price" && x.field !== "vat_WartoscStawki").concat(BarcodeColumn()) :
            compare ? supplyDetailsColumns :
            (anyDetailWithVat === false && vatSetOnUser === true) ?
              supplyDetailsColumns.filter(x => x.field !== "vat_WartoscStawki").concat(BarcodeColumn()) :
              supplyDetailsColumns.concat(BarcodeColumn())}
        rows={rowsDetails}
        editAction={editAction}
        loading={loadingInitializationDetails}
        setPaginationModel={paginationModelDetailsChange}
        paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}}
        total={totalDetails}
        setQueryOptions={() => null}
        doubleClickRowAction={() => {}}
        deleteAction={deleteSupplyDetailsAction}
        hideDeleteAction={compare || currentDostawa?.approvedBy || (appContext?.userData?.userRole === UserRole.dostawca && currentDostawa?.sendToSpgj)}
        hideEditAction={compare || currentDostawa?.approvedBy || (appContext?.userData?.userRole === UserRole.dostawca && currentDostawa?.sendToSpgj)}
        hideActionColumn={compare || currentDostawa?.approvedBy}
        checkboxSelection={false}
        disableRowSelectionOnClick={false}
        setRowsSelectionModel={() => null}
        rowSelectionModel={undefined}
        hideFooter={false}
        hideFooterSelectedRowCount={true}
        columnVisibilityModel={{}}
        setColumnVisibilityModel={() => null}
        columnGroupingModel={undefined}
				additionalStyles={undefined}
      />
      <ModalElement handleClose={handleCloseAddDetails} open={openDetails} additionalStyles={{width: '500px !important', top: '13% !important'}} centerCloseButton={false}
        modalContent={
          <AddDetailsModal
            currentDostawaId={currentDostawa?.id}
            initializeDetails={() => initializeDetails({pageSize: Number(appContext?.rowsNumberPageModel), page: pageDetails}, currentDostawa.id, false)}
            closeAddDetails={() => handleCloseAddDetails()}
            loadingButtons={loadingButtons}
            setLoadingButtons={setLoadingButtons}
            filteredOptionsTowary={filteredOptionsTowary}
            setOptionsTowary={setOptionsTowary}
            optionsTowary={optionsTowary}
            setFilteredOptionsTowary={setFilteredOptionsTowary}
            forceReset={forceResetOfDetailsItemAddForm}
            vatSetOnUser={vatSetOnUser}
          />
        }
      />
    </>)
  }

  const [filteredOptionsTowary, setFilteredOptionsTowary] = useState<any>([]);
  const getAllTowaryEcxept = async (dostawaId: number, refresh: boolean) => {
    if (filteredOptionsTowary.length === 0 || refresh) {
      await getAllTowaryExceptApi(dostawaId).then(
        async (response: AxiosResponse) => {
          setFilteredOptionsTowary(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    }
  };

  return (
    <><h1>{(appContext?.userData?.userRole === UserRole.dostawca) ? "Moje dostawy" : "Dostawy"}</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
      {(appContext?.userData?.userRole !== UserRole.dostawca) ? <Grid item xs={12} sm={8} md={4} lg={3}>
        <Autocomplete
          options={dostawcyOptions}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          onChange={(e, data: any) => {
            if (data != null) {
              setCurrentDostawcaId(data.id);
              initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, data.id);}
            else {
              setCurrentDostawcaId(null); initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, null);};
          }}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Wybierz dostawce"
            />
          )}
        />
      </Grid> : <></>}
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <LoadingButton className='customAddLoadingButton'
          endIcon={<AddIcon sx={{m: '0px', fontSize: "30px !important"}}/>}
          loading={loadingButtons}
          loadingPosition="center"
          disabled={currentDostawcaId === null}
          onClick={handleCreateSupply}>
        </LoadingButton>
      </Grid>
    </Grid>
    <EditableGrid
      columns={supplyColumns}
      rows={rows}
      editAction={async () => false}
      loading={loadingInitialization}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={(row: any) => doubleClickRowAction(row)}
      deleteAction={deleteSupplyAction}
      hideDeleteAction={false}
      hideEditAction={true}
      hideActionColumn={false}
      checkboxSelection={false}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={() => null}
      rowSelectionModel={undefined}
      hideFooter={false}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
     />
    <ModalElement handleClose={handleClose} open={open} modalContent={modalContent()} centerCloseButton={false} additionalStyles={{top: '10% !important'}}/>
    </>
  );
};

export default SuppliersPage;
