import * as React from 'react';
import { useRef, useState } from 'react';
import Button, { ButtonPropsColorOverrides } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';
import { OverridableStringUnion } from '@mui/types'
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';

export interface SplitButtonOptionProps {
  buttonText: string;
  buttonColor?: OverridableStringUnion<"primary" | "error" | "inherit" | "secondary" | "success" | "info" | "warning", ButtonPropsColorOverrides> | undefined;
  action(): void;
  loadingButtons: boolean;
}
interface SplitButtonOptionsProps {
  options: SplitButtonOptionProps[];
}
function SplitButton({options} : SplitButtonOptionsProps) {
  const appContext = React.useContext(AppContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = async () => {
    await options[selectedIndex].action();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        sx={{ width: '100%', maxWidth: '250px' }}
        variant="contained"
        ref={anchorRef}
        color={options[selectedIndex].buttonColor}
      >
        <LoadingButton sx={{ width: '100%' }}
          endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
          loading={options[selectedIndex].loadingButtons}
          loadingPosition="center"
          variant="contained"
          color={options[selectedIndex].buttonColor}
          onClick={handleClick} >{options[selectedIndex].buttonText}
        </LoadingButton>
        {appContext?.userData?.userRole === UserRole.admin &&
          <Button
            size="small"
            color={options[selectedIndex].buttonColor}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        }
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{boxShadow: 3, marginTop: "2px", border: "1px solid gray", borderRadius: "5px"}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.buttonText}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.buttonText}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default SplitButton;