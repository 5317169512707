import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";

export const getAllMagazynToWydanieDetailApi = (
  wydanieDetailId: number,
  itemsOnPage: number,
  pageNumber: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/GetAllMagazynToWydanieDetail`, {
      wydanieDetailId: wydanieDetailId,
      itemsOnPage: itemsOnPage,
      pageNumber: pageNumber
    }, { headers: CustomHeaders() });
};

export const searchInMagazynForTowarToAssignToWydanieDetailApi = (
  towarId: number,
  itemsOnPage: number,
  pageNumber: number,
  searchByBarcode: string) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/SearchInMagazynForTowarToAssignToWydanieDetail`, {
      towarId: towarId,
      itemsOnPage: itemsOnPage,
      pageNumber: pageNumber,
      searchByBarcode: searchByBarcode
    }, { headers: CustomHeaders() });
};

export const createMagazynToWydanieDetailApi = (
  quantity: number,
  dostawaDetailId: number,
  wydanieDetailsId: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/CreateMagazynToWydanieDetail`, {
      quantity: quantity,
      dostawaDetailId: dostawaDetailId,
      wydanieDetailsId: wydanieDetailsId
    }, { headers: CustomHeaders() });
};

export const removeMagazynToWydanieDetailApi = (magazynToWydanieDetailId: number) => {
  let query = `magazynToWydanieDetailId=${magazynToWydanieDetailId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Wydanie/RemoveMagazynToWydanieDetail?${query}`, { headers: CustomHeaders() });
};