import { GridRowId } from "@mui/x-data-grid";
import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";

export const getAllDostawy = (paginationModel: {pageSize: number; page: number;}, searchByOwnerId: number | null) => {
  let query = `itemsOnPage=${paginationModel.pageSize}&pageNumber=${paginationModel.page}`;
  
  if (searchByOwnerId !== null && searchByOwnerId !== undefined)
    query = query + `&searchByOwnerId=${searchByOwnerId}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/GetAllDostawy?${query}`, { headers: CustomHeaders() });
};

export const createDostawa = (ownerId: number | null) => {
  let query = "";
  
  if (ownerId !== null && ownerId !== undefined)
    query = query + `?ownerId=${ownerId}`;

  return axios
    .post(`${process.env.REACT_APP_API_URL}Dostawa/CreateDostawa${query}`, {}, { headers: CustomHeaders() });
};

export const removeDostawa = (dostawaId: number) => {
  let query = `dostawaId=${dostawaId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Dostawa/RemoveDostawa?${query}`, { headers: CustomHeaders() });
};

export const sendDostawaToSPGJApi = (dostawaId: number) => {
  let query = `dostawaId=${dostawaId}`;
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/SendDostawaToSPGJ?${query}`, { headers: CustomHeaders() });
};

export const approveDostawaApi = (dostawaId: number, skippAddPzToRaks: boolean = false) => {
  let query = `dostawaId=${dostawaId}&skippAddPzToRaks=${skippAddPzToRaks}`;
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/ApproveDostawa?${query}`, { headers: CustomHeaders() });
};




export const getDostawaDetails = (paginationModel: {pageSize: number; page: number;}, dostawaId: number, compare: boolean) => {
  let query = `itemsOnPage=${paginationModel.pageSize}&pageNumber=${paginationModel.page}&dostawaId=${dostawaId}`;
  let endpoint = compare ? "GetDostawaDetailsComparison" : "GetDostawaDetails";
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/${endpoint}?${query}`, { headers: CustomHeaders() });
};

export const createDostawaDetails = (towarId: number, quantity: number, dostawaId: number, vat_IdStawki: number | null, vat_WartoscStawki: number | null, price: number | null, additionalInfo: string | null) => {
  let query = `towarId=${towarId}&quantity=${quantity}&dostawaId=${dostawaId}`;

  if (vat_IdStawki !== null && vat_IdStawki !== undefined)
    query = query + `&vat_IdStawki=${vat_IdStawki}`;
  
  if (vat_WartoscStawki !== null && vat_WartoscStawki !== undefined)
    query = query + `&vat_WartoscStawki=${vat_WartoscStawki}`;
  
  if (price !== null && price !== undefined)
    query = query + `&price=${price}`;
    
  if (additionalInfo !== null && additionalInfo !== "" && additionalInfo !== undefined)
    query = query + `&additionalInfo=${additionalInfo}`;
  
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/CreateDostawaDetails?${query}`, { headers: CustomHeaders() });
};

export const editDostawaDetails = (id: number, quantity: number, dostawaId: number, vat_IdStawki: number | null, vat_WartoscStawki: number | null, price: number | null, additionalInfo: string | null) => {
  let query = `id=${id}&quantity=${quantity}&dostawaId=${dostawaId}`;

  if (vat_IdStawki !== null && vat_IdStawki !== undefined)
    query = query + `&vat_IdStawki=${vat_IdStawki}`;
  
  if (vat_WartoscStawki !== null && vat_WartoscStawki !== undefined)
    query = query + `&vat_WartoscStawki=${vat_WartoscStawki}`;
  
  if (price !== null && price !== undefined)
    query = query + `&price=${price}`;
    
  if (additionalInfo !== null && additionalInfo !== "" && additionalInfo !== undefined)
    query = query + `&additionalInfo=${additionalInfo}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/EditDostawaDetails?${query}`, { headers: CustomHeaders() });
};

export const removeSupplyDetail = (supplyId: number, supplyDetailId: number) => {
  let query = `dostawaId=${supplyId}&dostawaDetailId=${supplyDetailId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Dostawa/RemoveDostawaDetail?${query}`, { headers: CustomHeaders() });
};




export const getDostawcyApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}User/GetAllDostawcy`, { headers: CustomHeaders() });
};

export const getAllTowaryApi = (withJednostka: boolean = true) => {
  return axios.get(`${process.env.REACT_APP_API_URL}Dostawa/GetAllTowary?withJednostka=${withJednostka}`, { headers: CustomHeaders() });
};

export const getAllTowaryExceptApi = (dostawaId: number) => {
  let query = `dostawaId=${dostawaId}`;
  return axios.get(`${process.env.REACT_APP_API_URL}Dostawa/GetAllTowaryExcept?${query}`, { headers: CustomHeaders() });
};

export const getTowaryForDostawcaApi = (dostawcaId: number, dostawaId: number) => {
  let query = `dostawcaId=${dostawcaId}&dostawaId=${dostawaId}`;
  return axios.get(`${process.env.REACT_APP_API_URL}Dostawa/GetTowaryForDostawca?${query}`, { headers: CustomHeaders() });
};
export const getTowaryForDostawca2 = (dostawcaId: number) => {
  let query = `dostawcaId=${dostawcaId}`;
  return axios.get(`${process.env.REACT_APP_API_URL}Dostawa/GetTowaryForDostawca2?${query}`, { headers: CustomHeaders() });
};

export const assignTowaryToDostawca = (towayIds: number[], dostawcaId: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Dostawa/AssignTowaryToDostawca`, { towaryIds: towayIds, dostawcaId: dostawcaId }, { headers: CustomHeaders() });
};
export const editTowarForDostawca = (towarId: number, price: number) => {
  let query = `towarId=${towarId}&price=${price}`;
  return axios.get(`${process.env.REACT_APP_API_URL}Dostawa/EditTowarForDostawca?${query}`, { headers: CustomHeaders() });
};

export const removeTowaryFromDostawcaApi = (towayIds: GridRowId[]) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Dostawa/RemoveTowaryFromDostawca`, { towaryIds: towayIds }, { headers: CustomHeaders() });
};





export const getDostawcyWithCodeApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}User/GetAllDostawcyAndCode`, { headers: CustomHeaders() });
};




export const getUrlToPrintPzTemplate = (dostawaId: number) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetPzTemplate?dostawaId=${dostawaId}`;
};

export const getUrlToPrintWzTemplate = (wydanieId: number) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetWzTemplate?wydanieId=${wydanieId}`;
};

export const getUrlToPrintPwTemplate = (przychodId: number) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetPwTemplate?przychodId=${przychodId}`;
};

export const getUrlToPrintRwTemplate = (rozchodIds: number[]) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetRwTemplate?rozchodIds=${rozchodIds.join()}`;
};

export const getUrlToPrintRozwagaTemplate = (rozwagaIds: number[]) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetRozwagaTemplate?rozwagaIds=${rozwagaIds.join()}`;
};

export const getUrlToPrintZzTemplate = (zwrotIds: number[]) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetZwrotTemplate?zwrotIds=${zwrotIds.join()}`;
};



export const getUrlToBarcode = (code: string, codetitle: string) => {
  return `${process.env.REACT_APP_API_URL}HtmlTemplate/GetBarcode?code=${code}&codetitle=${codetitle}`;
};