import { IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import LineWeightIcon from '@mui/icons-material/LineWeight';
import { getUrlToBarcode, getUrlToPrintPwTemplate } from "../Services/SupplyService";
import PrintIcon from '@mui/icons-material/Print';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';

export function BarcodeColumn(): GridColDef {
  return {
    field: 'barcode',
    headerName: 'Kod kreskowy',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 140,
    cellClassName: 'barcode',
    headerAlign: "center",
    renderCell: ({ value, row }) => (
      <>
        {value &&
          <Tooltip title="Drukuj kod kreskowy">
            <IconButton onClick={() => window.open(getUrlToBarcode(row.barcode, row.towarName))} disabled={false} className='customAddLoadingButton' color={"primary"} >
              <LineWeightIcon style={{fontSize: '24px', color: 'black', transform: "rotate(90deg)", marginTop: "-1px"}}/>
            </IconButton>
          </Tooltip>}
        {value}
      </>
    )
  };
}

// .concat(PrintPwColumn())
export function PrintPwColumn(): GridColDef {
  return {
    field: 'printColumn',
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 40,
    cellClassName: 'printColumn',
    headerAlign: "center",
    align: "center",
    renderCell: ({ value, row }) => (
      <Tooltip title="Drukuj">
        <IconButton onClick={() => window.open(getUrlToPrintPwTemplate(row.id))} disabled={false} className='customAddLoadingButton' color={"primary"} >
          <PrintIcon style={{fontSize: '24px'}}/>
        </IconButton>
      </Tooltip>
    )
  };
}

export function ZwrotColumn(openZwrotModalAction: Dispatch<SetStateAction<boolean>> | null, setZwrotRow: Dispatch<SetStateAction<any>> | null, clickable: boolean): GridColDef {
  const handleOpenMagazynToWzDetailModal = useCallback(() => {
    openZwrotModalAction!(true);
  }, [openZwrotModalAction])

  const handleSetZwrotRow = useCallback((row: any) => {
    setZwrotRow!(row);
  }, [setZwrotRow])
  
  const ref = useRef<any>(null);
  const focus = () => {
    ref !== null && ref.current && ref.current.focus()
  }
  
  return {
    field: 'zwrot',
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 40,
    cellClassName: 'noPadding alignCenter',
    headerAlign: "center",
    renderCell: ({ value, row }) => (
      (clickable ?
        <Tooltip title={row.doneZwrot ? <>Zwrot towaru już zrobiony<br />Usuń zwrot zeby poprawić dane</> :  "Zrób zwrot towaru"} ref={ref}>
          <span>
            <IconButton onClick={() => { handleOpenMagazynToWzDetailModal(); handleSetZwrotRow(row); focus(); }} disabled={row.doneZwrot} className='customAddLoadingButton' color={"primary"} >
              {row.doneZwrot ? 
                <CallMissedOutgoingIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/> :
                <UTurnRightIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/>
              }
            </IconButton>
          </span>
        </Tooltip> :
        row.doneZwrot ?
          <IconButton disabled className='customAddLoadingButton' color={"primary"} >
            <CallMissedOutgoingIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/>
          </IconButton> :
          <></>
      )
    )
  };
}