import React, { useState, useContext, useCallback } from "react";
import LockIcon from '@mui/icons-material/Lock';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import { getCurrentUserData, login } from "../Services/UserService";
import { Jwt } from "../Models/Jwt";
import { AxiosResponse } from "axios";
import { AppContext } from "../App";
import { Debounce } from "../Helpers/ButtonDebouncing";
import { EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaProvider, ReCaptchaV2, TReCaptchaV2Callback } from 'react-recaptcha-x';
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { bake_cookie } from 'sfcookies';

interface LoginProps {
  setUserData: React.Dispatch<React.SetStateAction<Jwt | null>>;
}
function Login({setUserData} : LoginProps) {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [reCaptchaToken, setReCaptchaToken] = useState<string>("");
  const [rerenderAutocomplete, setRerenderAutocomplete] = useState(0);

  const handleSetUserData = useCallback((event: Jwt | null) => {
    setUserData(event)
  }, [setUserData])
  const handleUserName = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setUserName(event.target.value);
  }
  const handlePassword = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setPassword(event.target.value);
  }
  const handleLogin = async () =>{
    if (username === null || username === undefined || username === '' ||
      password === null || password === undefined || password === '' ||
      reCaptchaToken === null || reCaptchaToken === undefined || reCaptchaToken === '') {
      enqueueSnackbar('Dane logowania są nie prawidłowe.', { variant: 'error' });
      return;
    }
    setLoading(true);
    
    let ipAddress = '';
    await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => ipAddress = data.ip)
      .catch(error => console.log(error))

    await login(username, password, reCaptchaToken, ipAddress).then(
      async (response: AxiosResponse) => {
        if (response.data.token) {
          //localStorage.setItem("JWT", response.data.token);
          bake_cookie("JWT", response.data.token);
        }
        await Debounce(setLoading, false);
        handleSetUserData(getCurrentUserData());
        appContext?.updateIsLoggedIn(true);
        closeSnackbar();
      },
      (error) => {
        setReCaptchaToken('');
        setRerenderAutocomplete(rerenderAutocomplete + 1);
        if (error.response && error.response.status === 401) {
          enqueueSnackbar('Dane logowania są nie prawidłowe.', { variant: 'error' });
        } else if (error.response?.status === 500) {
          enqueueSnackbar('Wewnętrzny błąd serwera. Skontaktuj sie z administratorem.', { variant: 'error' });
        } else if (error.response?.status === 400) {
          if (error.response.data.message !== undefined) {
            enqueueSnackbar(`${error.response.data.message}`, { variant: 'error' });
          } else {
            enqueueSnackbar(`${error.response.data.errors.quantity}`, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(`${error.message}`, { variant: 'error' });
        }
        setLoading(false);
      }
    );
  };

  const v2Callback: TReCaptchaV2Callback = (
    token: string | false | Error
  ): void => {
    if (typeof token === 'string') {
      setReCaptchaToken(token);
    } else if (token instanceof Error) {
      enqueueSnackbar(`Błąd: ${token.message}.`, { variant: 'error' });
    }
  };

  return (
    <Container sx={{ pt: 3 }} maxWidth="sm">
        <Container>
          <Grid container alignItems="center" justifyContent="center" direction="column">      
            <LockIcon sx={{fontSize: 40, pt: 6}} />
            <Typography component="div" variant="h1" sx={{fontSize: 30, fontWeight: 300, pt: 2, pb: 1}}>
              Logowanie
            </Typography>
          </Grid>
            <TextField
              onChange={handleUserName}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nazwa użytkownika"
              name="username"
              autoFocus
              size="medium"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <TextField
              onChange={handlePassword}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
              size="medium"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <ReCaptchaProvider
              siteKeyV2="6LcHgDAqAAAAALD9_KjEqEPADCrcku56vlKEdmNJ"
              langCode="pl"
							key={rerenderAutocomplete}
            >
              <ReCaptchaV2
                className="captcha"
                callback={v2Callback}
                theme={EReCaptchaV2Theme.Light}
                size={EReCaptchaV2Size.Normal}
                tabindex={0}
              />
            </ReCaptchaProvider>
            <LoadingButton sx={{ width: 1, height: '56px', mt: 1.5 }}
              onClick={handleLogin}
              endIcon={<LoginIcon style={{fontSize: '34px'}} />}
              loading={loading}
              loadingPosition="center"
              variant="contained"
              disabled={!(reCaptchaToken !== null && reCaptchaToken !== undefined && reCaptchaToken !== '')}
              >
            </LoadingButton>
        </Container>
    </Container>
  );
};

export default Login;