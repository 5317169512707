import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import { actionWithTowarDescriptionColumns, wzColumn } from '../Models/MagazynActions';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { IdAndLabel } from '../Models/Supply';
import { PrzychodyRozchodyRozwagiFilter } from '../SiteComponents/PrzychodyRozchodyRozwagiFilter';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { getUrlToPrintZzTemplate } from '../Services/SupplyService';
import { getAllDostawcaForZwrotApi, getAllZwrotApi, removeZwrotApi } from '../Services/WydanieService';

function ZwrotyZewnetrznePage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [dostawcyOptions, setDostawcyOptions] = useState<IdAndLabel[]>([]);
  const [loadingAllZwrot, setLoadingAllZwrot] = useState<boolean>(false);
  const [loadingAllDostawca, setLoadingAllDostawca] = useState<boolean>(false);
  const [currentDostawcaId, setCurrentDostawcaId] = useState<number | null>(null);
  const [dateFromValueForSearch, setDateFromValueForSearch] = useState<Date | null>(null);
  const [dateToValueForSearch, setDateToValueForSearch] = useState<Date | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  
  const getAllDostawcaForZwrot = useCallback(async () => {
    setLoadingAllDostawca(true);
    await getAllDostawcaForZwrotApi().then(
      (response: AxiosResponse) => {
        setDostawcyOptions(response.data);
        setLoadingAllDostawca(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllDostawca(false);
      });
  }, [appContext]);

  const getAllZwrot = useCallback(async () => {
    setLoadingAllZwrot(true);
    await getAllZwrotApi({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId, dateFromValueForSearch, dateToValueForSearch).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setLoadingAllZwrot(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllZwrot(false);
      });
  }, [currentDostawcaId, dateFromValueForSearch, dateToValueForSearch, page, appContext]);

  const initialize = useCallback(async () => {
    setLoadingAllZwrot(true);
    getAllDostawcaForZwrot();
    getAllZwrot();
  }, [getAllDostawcaForZwrot, getAllZwrot]);
  useEffect(() => { initialize(); }, [initialize]);
  
  const deleteZwrotAction = async (row: any) => {
    setLoadingAllZwrot(true);
    await removeZwrotApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingAllZwrot(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllZwrot(false);
      }
    );
  };

  const rowSelectionModelChange = (param: any) => {
    setRowSelectionModel(param);
  };

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Zwroty Zewnętrzne</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
    {rowSelectionModel.length > 0 &&
      <Grid item xs={12} sm={12} md={12} lg={'auto'}>
        <LoadingButton sx={{ lineHeight: '33px' }}
          endIcon={<PrintIcon style={{fontSize: '30px'}}/>}
          loadingPosition="end"
          variant="outlined"
          onClick={() => window.open(getUrlToPrintZzTemplate(rowSelectionModel.map(s => (Number(s)))))}>Drukuj zaznaczone
        </LoadingButton>
      </Grid>}
      <PrzychodyRozchodyRozwagiFilter
        dostawcyOptions={dostawcyOptions}
        loadingAllDostawca={loadingAllDostawca}
        setCurrentDostawcaId={setCurrentDostawcaId}
        setDateFromValueForSearch={setDateFromValueForSearch}
        setDateToValueForSearch={setDateToValueForSearch}
      />
    </Grid>
    <EditableGrid
      columns={actionWithTowarDescriptionColumns.slice(0, 7).concat(wzColumn).concat(actionWithTowarDescriptionColumns.slice(7))}
      rows={rows}
      editAction={async () => false}
      loading={loadingAllZwrot}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={deleteZwrotAction}
      hideDeleteAction={false}
      hideEditAction={true}
      hideActionColumn={false}
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      setRowsSelectionModel={rowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      hideFooter={false}
      hideFooterSelectedRowCount={false}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default ZwrotyZewnetrznePage;