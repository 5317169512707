export function allowOnlyNumbers(event: any, withDot: boolean) {
  let regex;
  if (withDot) {
    regex = /[^0-9.]/g;
  } else {
    regex = /[^0-9]/g;
  }

  let value = (event.target as HTMLInputElement).value;
  if (value !== null && value !== undefined && value !== '') {    
    value = value.replaceAll(',', '.');
    while (value.lastIndexOf('.') !== value.indexOf('.')) {
      value = value.slice(0, value.lastIndexOf('.')) + value.slice(value.lastIndexOf('.') + 1);
    }
    (event.target as HTMLInputElement).value = value.replace(regex, '').toString().slice(0, 10);
  }
}
