import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";
import { Jwt, UserRole, UserStatus } from "../Models/Jwt";
import { read_cookie } from 'sfcookies';

const base64Decode = (base64EncodedString: string) =>
  new TextDecoder().decode(Uint8Array.from(atob(base64EncodedString), m => m.charCodeAt(0)));

export const login = (username: string, password: string, reCaptchaToken: string, ipAddress: string) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}User/Login`, {
      login: username,
      password: password,
      reCaptchaToken: reCaptchaToken,
      ipAddress: ipAddress
    });
};

export const getCurrentUserData = () => {
  //let token = localStorage.getItem('JWT') ?? "";
  let token = read_cookie('JWT');
  if (typeof token !== "string")
    return null;

  let decodedJWT: Jwt = JSON.parse(base64Decode(token.split('.')[1]));
  if (!decodedJWT || !decodedJWT.exp)
    return null;
  else
    return decodedJWT;
};

export const checkIfUserIsLogged = () => {
  //let token = localStorage.getItem('JWT') ?? "";
  let token = read_cookie('JWT');
  if (typeof token !== "string")
    return false;

  let decodedJWT: Jwt = JSON.parse(base64Decode(token.split('.')[1]));
  if (!decodedJWT || !decodedJWT.exp)
    return false;

  if (new Date() > new Date(decodedJWT.exp * 1000)) {
    return false;
  } else {
    return true;
  }
}

export const getAllUsers = (paginationModel: {pageSize: number; page: number;}, searchUserName: string) => {
  let query = `itemsOnPage=${paginationModel.pageSize}&pageNumber=${paginationModel.page}`;

  if (searchUserName !== null && searchUserName !== "" && searchUserName !== undefined)
    query = query + `&searchUserName=${searchUserName}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}User/GetAllUsers?${query}`, { headers: CustomHeaders() });
};

export const searchDostawcaByNameApi = (searchName: string) => {
  let query = `dostawcaSearchName=${searchName}`;
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/SearchDostawca?${query}`, { headers: CustomHeaders() });
};

export const getAllLokalizacje = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}Dostawa/GetAllLokalizacje`, { headers: CustomHeaders() });
};

export const editUser = (
  id: number,
  userName: string,
  password: string | null,
  userRole: UserRole,
  status: UserStatus,
  userNameRaks: string | null,
  idDostawcaRaks: number | null,
  magazynNumberRaks: string | null,
  idLokalizacjiRaks: number | null,
  vat_IdStawki: number | null,
  vat_WartoscStawki: number | null,
  producentCode: string | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}User/EditUser`, {
      id: id,
      login: userName,
      password: password !== null && password !== "" && password !== undefined ? password : null,
      userRole: userRole,
      status: status,
      userNameRaks: userNameRaks !== null && userNameRaks !== "" && userNameRaks !== undefined ? userNameRaks : null,
      idDostawcaRaks: idDostawcaRaks !== null && idDostawcaRaks !== undefined ? idDostawcaRaks : null,
      magazynNumberRaks: magazynNumberRaks !== null && magazynNumberRaks !== "" && magazynNumberRaks !== undefined ? magazynNumberRaks : null,
      idLokalizacjiRaks: idLokalizacjiRaks !== null && idLokalizacjiRaks !== undefined ? idLokalizacjiRaks : null,
      vat_IdStawki: vat_IdStawki !== null && vat_IdStawki !== undefined ? vat_IdStawki : null,
      vat_WartoscStawki: vat_WartoscStawki !== null && vat_WartoscStawki !== undefined ? vat_WartoscStawki : null,
      producentCode: producentCode !== null && producentCode !== undefined ? producentCode : null
    }, { headers: CustomHeaders() });
};

export const addUser = (
  username: string,
  password: string,
  userRole: UserRole,
  userNameRaks: string | null,
  idDostawcaRaks: number | null,
  magazynNumberRaks: string | null,
  idLokalizacjiRaks: number | null,
  vat_IdStawki: number | null,
  vat_WartoscStawki: number | null,
  producentCode: number | null) => {
  let query = `login=${username}&password=${password}&userRole=${userRole}`;

  if (userNameRaks !== null && userNameRaks !== "" && userNameRaks !== undefined)
    query = query + `&userNameRaks=${userNameRaks}`;
  
  if (idDostawcaRaks !== null && idDostawcaRaks !== undefined)
    query = query + `&idDostawcaRaks=${idDostawcaRaks}`;
  
  if (magazynNumberRaks !== null && magazynNumberRaks !== "" && magazynNumberRaks !== undefined)
    query = query + `&magazynNumberRaks=${magazynNumberRaks}`;

  if (idLokalizacjiRaks !== null && idLokalizacjiRaks !== undefined)
    query = query + `&idLokalizacjiRaks=${idLokalizacjiRaks}`;

  if (vat_IdStawki !== null && vat_IdStawki !== undefined)
  query = query + `&vat_IdStawki=${vat_IdStawki}`;
  
  if (vat_WartoscStawki !== null && vat_WartoscStawki !== undefined)
    query = query + `&vat_WartoscStawki=${vat_WartoscStawki}`;

  if (producentCode !== null && producentCode !== undefined)
    query = query + `&producentCode=${producentCode}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}User/CreateUser?${query}`,
    { headers: CustomHeaders() });
};