import EditableGrid from '../../Features/EditableGrid';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros } from '../../Helpers/AxiosCustomHeaders';
import { IPrzychodWewnetrzny, przychodWewnetrznyColumns } from '../../Models/Magazyn';
import { IActionWithTowarDescription } from '../../Models/MagazynActions';
import { getDetailsOfPrzychodApi } from '../../Services/MagazynService';
import { Button, Grid } from '@mui/material';
import { getUrlToPrintPwTemplate } from '../../Services/SupplyService';
import PrintIcon from '@mui/icons-material/Print';
import { AppContext } from '../../App';

interface OpenPrzychodWewnetrznyModalProps {
	doubleClickedPwRow: IActionWithTowarDescription;
}
export function OpenPrzychodWewnetrznyModal({ doubleClickedPwRow }: OpenPrzychodWewnetrznyModalProps) {
  const appContext = useContext(AppContext);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [rows, setRows] = useState<IPrzychodWewnetrzny[]>([]);
  //const [price, setPrice] = useState('');

  const initialize = useCallback(async () => {
		setLoadingInitialization(true);
		await getDetailsOfPrzychodApi(doubleClickedPwRow.id).then(
			(response: AxiosResponse<IPrzychodWewnetrzny[]>) => {
				setRows(response.data);
				setLoadingInitialization(false);

				// let x = new Decimal(doubleClickedPwRow.quantity);
        // let y = new Decimal(doubleClickedPwRow.sum);
        // setPrice(y.dividedBy(x).toFixed(2).toString());
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingInitialization(false);
			});
  }, [doubleClickedPwRow.id, appContext]);
	useEffect(() => { initialize(); }, [initialize]);

	return (
		<>
			<h1 style={{ margin: '0 0 20px 0' }}>{doubleClickedPwRow.withPriceValidation ? 'Przychód wewnetrzny' : 'Zmiana wewnetrzna'}</h1>
			<Button sx={{ width: '100%', maxWidth: '250px', marginTop: '-20px', marginBottom: '10px', marginLeft: 'auto', marginRight: 'auto' }}
				endIcon={<PrintIcon style={{fontSize: '30px'}}/>}
				variant={"contained"}
				target='_blank'
				href={getUrlToPrintPwTemplate(doubleClickedPwRow.id)} >Drukuj
      </Button>
			<Grid container spacing={2} sx={{textAlign: 'left'}}>
				<Grid item xs={2} sm={1.5} md={1.5}>
					<b>Towar:</b>
				</Grid>
				<Grid item xs={10} sm={6} md={6}>
					{doubleClickedPwRow.towarName}
				</Grid>
				<Grid item xs={2} sm={1.5} md={1.5}>
					<b>Dost.:</b>
				</Grid>
				<Grid item xs={10} sm={3} md={3}>
					{doubleClickedPwRow.ownerName}
				</Grid>

				<Grid item xs={2} sm={1.5} md={1.5}>
					<b>Uwagi:</b>
				</Grid>
				<Grid item xs={10} sm={6} md={6}>
					{doubleClickedPwRow.note}
				</Grid>
				<Grid item xs={2} sm={1.5} md={1.5}>
					<b>PZ:</b>
				</Grid>
				<Grid item xs={10} sm={3} md={3}>
					{doubleClickedPwRow.numberPZ}
				</Grid>
			</Grid>

			<Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" columns={{ xs: 12, sm: 12 }} sx={{pt: '15px'}}>
				<Grid item xs={4} sm={3}>
					<b>Ilość:</b> &nbsp; {doubleClickedPwRow.quantity}
				</Grid>
				<Grid item xs={4} sm={3}>
					<b>Cena:</b> &nbsp; {doubleClickedPwRow.price} zł
				</Grid>
				<Grid item xs={4} sm={3}>
					<b>Suma:</b> &nbsp; {doubleClickedPwRow.sum} zł
				</Grid>
			</Grid>
			<EditableGrid
				columns={przychodWewnetrznyColumns}
				rows={rows}
				editAction={async () => false}
				loading={loadingInitialization}
				setPaginationModel={() => null}
				paginationModel={{pageSize: 100, page: 0}}
				total={0}
				setQueryOptions={() => null}
				doubleClickRowAction={() => {}}
				deleteAction={() => null}
				hideDeleteAction={true}
				hideEditAction={true}
				hideActionColumn={true}
				checkboxSelection={false}
				disableRowSelectionOnClick={false}
				setRowsSelectionModel={() => null}
				rowSelectionModel={undefined}
				hideFooter={true}
				hideFooterSelectedRowCount={true}
				columnVisibilityModel={{}}
				setColumnVisibilityModel={() => null}
				columnGroupingModel={undefined}
				additionalStyles={{width: '100%', paddingTop: '15px'}} />
		</>
	);
}