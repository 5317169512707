import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { userColumns, UserRole } from '../Models/Jwt';
import { addUser, editUser, getAllLokalizacje, getAllUsers, searchDostawcaByNameApi } from '../Services/UserService';
import EditableGrid from '../Features/EditableGrid';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import { AxiosError, AxiosResponse } from 'axios';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UploadIcon from '@mui/icons-material/Upload';
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, Box, darken, Grid, lighten, styled, TextField } from '@mui/material';
import ModalElement from '../Features/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppContext } from '../App';
import { getVatKeyByValue, Vat_enum, vat_options } from '../Models/Supply';
import { Debounce } from '../Helpers/ButtonDebouncing';
import { GridFilterModel } from '@mui/x-data-grid';
import { delete_cookie } from 'sfcookies';

function UsersPage() {
  const appContext = useContext(AppContext);
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [queryOptions, setQueryOptions] = useState<GridFilterModel | undefined>();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); getLokalizacjeRaks(); }
  const handleClose = () => {
    reset();
    setOpen(false);
    setOptionsForDostawcy([]);
    setChoosenNewUserRole(UserRole.admin);
  }

  const [optionsForDostawcy, setOptionsForDostawcy] = useState([]);
  const onIdDostawcaRaksInputChange = (event: any, value: string, reason: any) => {
    if (value) {
      searchDostawcaByNameApi(value).then(
        (response: AxiosResponse) => {
          setOptionsForDostawcy(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    } else {
      setOptionsForDostawcy([]);
    }
  };

  const [optionsForLokalizacje, setOptionsForLokalizacje] = useState([]);
  const getLokalizacjeRaks = async () => {
    await getAllLokalizacje().then(
      (response: AxiosResponse) => {
        setOptionsForLokalizacje(response.data)
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      }
    );
  };

  const initialize = useCallback(async (paginationModel: {pageSize: number; page: number;}, queryOptions: GridFilterModel | undefined) => {
    setLoadingInitialization(true);
    await getAllUsers(paginationModel, queryOptions?.items[0]?.value).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setLoadingInitialization(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    )
  }, [appContext]);
  useEffect(() => { initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, queryOptions); }, [initialize, appContext?.rowsNumberPageModel, page, queryOptions]);

  const editAction = async (newRow: any, oldRow: any) : Promise<boolean> => {
    return await editUser(newRow.id, newRow.userName, newRow.password, newRow.userRole, newRow.status, newRow.userNameRaks, newRow.idDostawcaRaks,
      newRow.magazynNumberRaks, newRow.idLokalizacjiRaks, 
      Number(getVatKeyByValue(newRow.vat_WartoscStawkiRaks).replace('_', '')) || null,
      newRow.vat_WartoscStawkiRaks === Vat_enum._8 ? 0 : Number(newRow.vat_WartoscStawkiRaks) || null,
      newRow.producentCode)
      .then(
        (response: AxiosResponse) => {
          handleSuccess(response);
          
          if (newRow.password != null) newRow.password = "";
          if (newRow.forceLogin != null) newRow.forceLogin = true;
            
          if (oldRow.userName === appContext?.userData?.userName) {
            //localStorage.removeItem("JWT");
            delete_cookie("JWT");
            appContext?.updateIsLoggedIn(false);
          }

          return true;
        },
        (error: any) => {
          handleErros(error, appContext);
          return false;
        }
      );
  };

  const { control, handleSubmit, reset, resetField } = useForm({
    reValidateMode: "onBlur"
  });
  const addAction = async (data: any) => {
    setLoadingCreateButton(true);
    await addUser(data.userName, data.password, data.userRole, data.userNameRaks, data.idDostawcaRaks?.id, data.magazynNumberRaks, data.idLokalizacjiRaks?.key,
			Number(data?.vat?.id) || null, data?.vat?.label === "0.00" ? 0 : Number(data?.vat?.label) || null, data.producentCode).then(
      async (response: AxiosResponse) => {
        handleSuccess(response);
        await Debounce(setLoadingCreateButton, false);
        handleClose();
        initialize({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, queryOptions);
        reset();
      },
      (error: any) => {
        handleErros(error, appContext);
        setLoadingCreateButton(false);
      }
    )
  };

  const options = Object.values(UserRole);
  const [choosenNewUserRole, setChoosenNewUserRole] = useState<UserRole | null>(UserRole.admin);
  const modalContent = () => {
    return (
      <Box component="form" onSubmit={handleSubmit(addAction)} textAlign="center">
        <h1 style={{ margin: '0 0 20px 0' }}>Dodaj użytkownika</h1>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="userRole"
              defaultValue={options[0]}
              rules={{required: true}}
              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  options={options}
                  onChange={(_, data) => {
                    onChange(data);
                    setOptionsForDostawcy([]);
                    setChoosenNewUserRole(data);
                    resetField("userNameRaks"); resetField("idDostawcaRaks"); resetField("magazynNumberRaks"); resetField("idLokalizacjiRaks");}}
                  defaultValue={options[0]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      fullWidth
                      inputRef={ref}
                      variant="filled"
                      label="Rola"
                      error={error !== undefined}
                      helperText={error ?
                        error.type === "required" ? "Rola jest obowiązkowa." : error.message
                        : ""}
                    />
                  )}
                  clearIcon={null}
                />
              )}
            />
          </Grid>
          {choosenNewUserRole === UserRole.dostawca && <Grid item xs={12}>
            <Controller
              control={control}
              name="idDostawcaRaks"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={optionsForDostawcy}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderOption={(props, option: any) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  classes={{ paper: "optionsForIdDostawcaRaks" }}
                  onChange={(e, data) => {onChange(data);}}
                  onInputChange={onIdDostawcaRaksInputChange}
                  defaultValue={null}
                  noOptionsText='Zacznij pisac żeby zobaczyć opcje'
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      fullWidth
                      variant="filled"
                      label={`Wybierz dostawce z Raksa`}
                      autoComplete="cc-number"
                      />
                    }
                />
              )}
            />
          </Grid>}
          <Grid item xs={12}>
            <Controller
              control={control}
              name="userName"
              defaultValue=""
              rules={{required: true}}
              render={({ field: { ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label="Nazwa użytkownika w aplikacji"
                  error={error !== undefined}
                  autoComplete="cc-number"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              rules={{required: true}}
              render={({ field: { ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  type={'password'}
                  label="Hasło użytkownika do aplikacji"
                  error={error !== undefined}
                  autoComplete="new-password"
                />
              )}
            />
          </Grid>
          {(choosenNewUserRole === UserRole.admin || choosenNewUserRole === UserRole.magazynier) && <Grid item xs={12}>
            <Controller
              control={control}
              name="userNameRaks"
              defaultValue=""
              render={({ field: { ...field }}) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label="Nazwa użytkownika w Raks(użytkownik Raksa)"
                  autoComplete="cc-number"
                />
              )}
            />
          </Grid>}
          {choosenNewUserRole === UserRole.dostawca && <Grid item xs={12}>
            <Controller
              control={control}
              name="magazynNumberRaks"
              defaultValue=""
              render={({ field: { ...field }}) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label="Numer magazynu w Raksie"
                  autoComplete="cc-number"
                />
              )}
            />
          </Grid>}
          {choosenNewUserRole === UserRole.dostawca && <Grid item xs={12}>
            <Controller
              control={control}
              name="idLokalizacjiRaks"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={optionsForLokalizacje}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderOption={(props, option: any) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  groupBy={(option) => option.nazwaGrupy}
                  renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader onClick={() => {
                          (document.getElementById("groupItems" + params.key)!).style.display === "none" ?
                            (document.getElementById("groupItems" + params.key)!).style.display = "block" :
                            (document.getElementById("groupItems" + params.key)!).style.display = "none";
                        }}>Magazyn {params.group} ({(params.children as any).length})</GroupHeader>
                      <GroupItems id={"groupItems" + params.key} style={{ display: "block" }}>{params.children}</GroupItems>
                    </li>
                  )}
                  onChange={(e, data) => {onChange(data);}}
                  defaultValue={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="filled"
                      label={`Lokalizacja`}
                      autoComplete="cc-number"
                    />
                  )}
                />
              )}
            />
          </Grid>}
          {choosenNewUserRole === UserRole.dostawca && <Grid item xs={12}>
            <Controller
              control={control}
              name="vat"
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  options={vat_options}
                  onChange={(_, data) => onChange(data)}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderOption={(props, option: any) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      fullWidth
                      inputRef={ref}
                      variant="filled"
                      label="Vat"
                      error={error !== undefined}
                    />
                  )}
                />
              )}
            />
          </Grid>}
          {choosenNewUserRole === UserRole.dostawca && <Grid item xs={12}>
            <Controller
              control={control}
              name="producentCode"
              defaultValue=""
              render={({ field: { ...field }}) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label="Kod producenta"
                  autoComplete="cc-number"
                />
              )}
            />
          </Grid>}
          <Grid item xs={12}>
            <LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
              endIcon={<UploadIcon style={{fontSize: '30px'}} />}
              loading={loadingCreateButton}
              loadingPosition="center"
              variant="contained"
              type='submit'
              >
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    (appContext?.userData?.userRole === UserRole.admin) ?
    <>
    <h1>Użytkownicy</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}} sx={{marginTop: "-10px !important"}}>
      <Grid item >
        <IconButton sx={{mt: '-20px', mb: '6px'}} onClick={handleOpen} >
          <PersonAddIcon />
        </IconButton>
      </Grid>
    </Grid>
    <EditableGrid
      columns={userColumns}
      rows={rows}
      editAction={editAction}
      loading={loadingInitialization}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={(param: GridFilterModel) => {param.items[0].id = undefined; setQueryOptions(param);}}
      doubleClickRowAction={() => {}}
      deleteAction={() => {}}
      hideDeleteAction={true}
      hideEditAction={false}
      hideActionColumn={false}
      checkboxSelection={false}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={() => null}
      rowSelectionModel={undefined}
      hideFooter={false}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={appContext?.columnVisibilityInUsersPageModel}
      setColumnVisibilityModel={appContext?.setColumnVisibilityInUsersPageModel}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    <ModalElement handleClose={handleClose} open={open} modalContent={modalContent()} centerCloseButton={false} additionalStyles={{width: '450px !important', top: '10% !important'}}/>
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default UsersPage;

const GroupHeader = styled('div')(({ theme }) => ({

	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	zIndex: 1,
	color: theme.palette.primary.main,
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 0.85)
			: darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled('ul')({
	padding: 0,
});