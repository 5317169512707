import { GridRowId } from "@mui/x-data-grid";
import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";

export const getAllDetailsOfTowarForOdbiorcaApi = (odbiorcaId: number) => {
  let query = `odbiorcaId=${odbiorcaId}`;
  return axios.get(`${process.env.REACT_APP_API_URL}DetailsOfTowarForOdbiorca/GetAllDetailsOfTowarForOdbiorca?${query}`, { headers: CustomHeaders() });
};

export const createDetailsOfTowarForOdbiorcaApi = (
  odbiorcaId: number, towarId: number, name: string, threeKg: number, epsOneZeroFour: number, epsOneThreeSix: number, carton: number, tenKg: number) => {
  let query = `odbiorcaId=${odbiorcaId}`;
  query = query + `&towarId=${towarId}`;
  
  if (name !== null || name !== "" || name !== undefined)
    query = query + `&name=${name}`;

  query = query + `&threeKg=${threeKg}`;
  query = query + `&epsOneZeroFour=${epsOneZeroFour}`;
  query = query + `&epsOneThreeSix=${epsOneThreeSix}`;
  query = query + `&carton=${carton}`;
  query = query + `&tenKg=${tenKg}`;

  return axios.get(`${process.env.REACT_APP_API_URL}DetailsOfTowarForOdbiorca/CreateDetailsOfTowarForOdbiorca?${query}`, { headers: CustomHeaders() });
};

export const removeDetailsOfTowarForOdbiorcaApi = (ids: GridRowId[]) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}DetailsOfTowarForOdbiorca/RemoveDetailsOfTowarForOdbiorca`, { towaryIds: ids, dostawcaId: 0 }, { headers: CustomHeaders() });
};

export const editDetailsOfTowarForOdbiorcaApi = (
  id: number,
  name: string,
  threeKg: number,
  epsOneZeroFour: number,
  epsOneThreeSix: number,
  carton: number,
  tenKg: number,
  price: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}DetailsOfTowarForOdbiorca/EditDetailsOfTowarForOdbiorca`, {
      id: id,
      name: name !== null && name !== "" && name !== undefined ? name : null,
      threeKg: threeKg,
      epsOneZeroFour: epsOneZeroFour,
      epsOneThreeSix: epsOneThreeSix,
      carton: carton,
      tenKg: tenKg,
      price: price
    }, { headers: CustomHeaders() });
};

export const getAllOdbiorcaWithDetailsInDbApi = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}DetailsOfTowarForOdbiorca/GetAllOdbiorcaWithDetailsInDb`, { headers: CustomHeaders() });
};